import React, { useEffect, useState } from "react";
import "../App.css";
import { IGuest } from "../components/IGuest";
import { Card, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../api";
import GuestsStatisticsCard from "../components/GuestStatisticsCard";
import GuestsTable from "../components/GuestsTable";
import GuestModal from "../components/GuestModal";

const Guests: React.FC = () => {
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [guestId, setGuestId] = useState<string>("");
  const [invitationIdColors, setInvitationIdColors] = useState<
    Map<string, string>
  >(new Map());

  const handleOpenModal = (guest: IGuest) => {
    setGuestId(guest.id);
    setOpen(true);
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat, sans-serif",
    },
  });

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchGuests();
  }, []);

  const fetchGuests = async () => {
    try {
      const response = await api.guests.getAllGuests();
      if (response.status !== 200) {
        throw new Error("Failed to fetch");
      }
      setGuests(response.data);
    } catch (error) {
      console.error("Error fetching guests data:", error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const decideColorForInvitationId = (invitationId: string): string => {
    if (!invitationIdColors.has(invitationId)) {
      const color = invitationIdColors.size % 2 === 0 ? "#f0f0f0" : "#d9d9d9";
      setInvitationIdColors(
        new Map(invitationIdColors.set(invitationId, color))
      );
      return color;
    }
    return invitationIdColors.get(invitationId)!;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="App-header">
          <GuestsStatisticsCard />
          <div className="guests-table">
            <Card
              sx={{
                padding: "20px",
                borderRadius: "16px",
                margin: "auto",
                mt: 4,
                overflow: "hidden",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{ p: 2, textAlign: "center" }}
              ></Typography>
              <GuestsTable
                guests={guests}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleOpenModal={handleOpenModal}
                decideColorForInvitationId={decideColorForInvitationId}
              />
            </Card>
          </div>
        </div>
      </div>
      <GuestModal
        isOpen={open}
        onClose={handleCloseModal}
        onSubmit={fetchGuests}
        guestId={guestId}
      />
      {/* <GuestEditDialog
        open={open}
        onClose={handleCloseModal}
        guest={currentGuest}
        onUpdateGuest={fetchGuests}
      /> */}
    </ThemeProvider>
  );
};

export default Guests;
