import { useState } from "react";
import Accordion from "../components/Accordion";
import Agenda from "../sections/Agenda";
import ArrowToLeft from "../components/ArrowToLeft";
import { NavLink } from "react-router-dom";

interface AccordionItem {
  title: string;
  content: JSX.Element;
}

interface SectionData {
  sectionTitle: string;
  sectionSubtitle?: string;
  accordions: AccordionItem[];
}

export const Details = () => {
  const sectionsData: SectionData[] = [
    {
      sectionTitle: "MENU",
      sectionSubtitle: "PLAN POSIŁKÓW",
      accordions: [
        {
          title: "I DANIE",
          content: (
            <div>
              <ol>
                <li>
                  Esencjonalny rosół z kury, wołowiny i indyka z makaronem,
                  marchewką i pietruszką
                </li>
                <li>Schab faszerowany szpinakiem i fetą</li>
                <li>Sakiewka wieprzowa z boczkiem, śliwką i chrzanem</li>
                <li>Filet w cieście z porem i majonezem</li>
                <li>Glazurowane steki drobiowe</li>
                <li>Kotlet schabowy zapieczony z farszem pieczarkowym</li>
                <li>
                  Pierś drobiowa w ziołowej panierce z pomidorem i mozarellą
                </li>
                <li>Polędwiczki drobiowe w sosie śmietanowo-borowikowym</li>
                <li>Ziemniaki gotowane</li>
                <li>Gnocchi</li>
                <li>Słodka kapusta zasmażana z boczkiem, cebulą i koperkiem</li>
                <li>Surówka z białej kapusty</li>
                <li>Surówka z buraka</li>
              </ol>
              DESER: Mus truskawkowy podawany z lodami i bitą śmietaną
            </div>
          ),
        },
        {
          title: "BUFET",
          content: (
            <div>
              Po zakończonym serwisie obiadu pojawi się bufet z gorącymi
              daniami, uzupełniany do trzeciego posiłku. W skład jego wchodzą:
              <ol>
                <li>Ragout wieprzowe z warzywami</li>
                <li>
                  Duszone żeberka w delikatnym sosie z ziemniakami w łupinach,
                  marchewką i brukselką
                </li>
                <li>Bigos staropolski</li>
              </ol>
            </div>
          ),
        },
        {
          title: "II DANIE",
          content: (
            <ol>
              <li>Szaszłyk drobiowy z papryką, cebulą i boczkiem</li>
              <li>Grillowany stek z karkówki</li>
              <li>Żeberka pieczone w śliwkowym sosie barbecue</li>
              <li>Sola na sosie cytrusowym z zielonymi warzywami</li>
              <li>Frytki</li>
              <li>Ryż w curry, kukurydzą i papryką</li>
              <li>Warzywa z patelni</li>
              <li>Surówka z kapusty kiszonej</li>
            </ol>
          ),
        },
        {
          title: "III DANIE",
          content: (
            <div>
              Serwowane przez Szefa Kuchni płonące szynki na sosie myśliwskim
              podawane w asyście kaszy pęczak z grzybami i ogórkiem kiszonym
            </div>
          ),
        },
        {
          title: "IV DANIE",
          content: (
            <ol>
              <li>Tradycyjny barszcz czerwony</li>
              <li>Pierogi ruskie</li>
              <li>Pierogi z kapustą i grzybami</li>
              <li>Pierogi z mięsem</li>
            </ol>
          ),
        },
        {
          title: "V DANIE",
          content: <ol>Żurek staropolski z białą kiełbasą i prawdziwkami</ol>,
        },
        {
          title: "ZAKĄSKI",
          content: (
            <ol>
              <li>
                Wytrwany pstrąg faszerowany suszonym pomidorem z sosem
                jogurtowo-koperkowym
              </li>
              <li>Rolada z boczku</li>
              <li>Wolno pieczony schab ze śliwką</li>
              <li>Filet z kurczaka pieczony w maśle</li>
              <li>Rolada szpinakowa z wędzonym łososiem i pastą chrzanową</li>
              <li>
                Pate z królika z borówką, marynatami i bagietką z masłem
                ziołowym
              </li>
              <li>Pasztet z żurawiną</li>
              <li>Sushi z wędzonym łososiem</li>
              <li>Sushi wegetariańskie</li>
              <li>Wrapy z kurczakiem</li>
              <li>Wrapy wegetariańskie</li>
              <li>Tradycyjny matias z kolorową cebulką w oleju</li>
              <li>Śledzik w śmietanie</li>
              <li>Burchetta z pastą chrzanową, jajkiem i bekonem</li>
              <li>Sałatka grecka</li>
              <li>Sałatka brokułowa</li>
              <li>Sałatka z grillowanym kurczakiem</li>
              <li>Sałatka capresse</li>
            </ol>
          ),
        },
        {
          title: "BUFET SŁODKI",
          content: (
            <ol>
              <li>Ciasta 4 gatunki</li>
              <li>Paleta mini deserków</li>
              <li>Babeczki i kuleczki rumowe</li>
              <li>Muffinki cytrynowe i czekoladowe</li>
              <li>Kompozycja owoców</li>
            </ol>
          ),
        },
      ],
    },
    {
      sectionTitle: "FAQ",
      sectionSubtitle: "CZĘSTO ZADAWANE PYTANIA",
      accordions: [
        {
          title: "Jak dostać się z Katedry na salę weselną?",
          content: (
            <div>
              Z Katedry do sali weselnej można łatwo dotrzeć w około 30 minut
              samochodem, więc nie musicie się martwić o trasę. Dla waszej
              wygody przygotowaliśmy także specjalny autobus, który będzie
              czekał, by zapewnić wygodny transfer dla wszystkich naszych gości.
            </div>
          ),
        },
        {
          title: "Czy w pobliżu Katedry dostępny jest parking?",
          content: (
            <div>
              Oczywiście, istnieje możliwość parkowania pod samą Katedrą!
              Zachęcamy do kierowania się w kierunku ul. Skorupki z ul.
              Wólczańskiej, gdzie znajdziecie wygodne miejsce na parkingu.
            </div>
          ),
        },

        {
          title: "Czy dostępny będzie transfer do miejsc noclegowych?",
          content: (
            <div>
              Tak, dla wygody naszych gości zapewniamy transfer z sali weselnej
              do miejsc noclegowych. Do dyspozycji gości z okolic Bełchatowa
              będzie 22-osobowy bus, który wyruszy z pod o godzinie 05:00. Gości
              z okolic Łodzi będą rozwozić dwa ośmioosobowe busy, które w
              pierwszy kurs wyjadą o godz. 03:00
              {/* z dedykowanym kierowcą. Numer telefonu zostanie zaktualizowany w
              zakładce{" "}
              <NavLink
                to="/dojazd"
                // style={{ color: "var(--Biel)", textDecoration: "underline" }}
              >
                Dojazd
              </NavLink>
              . */}
            </div>
          ),
        },
        {
          title: "O której godzinie mam przybyć na ceremonię?",
          content: (
            <div>
              Ceremonia rozpoczyna się o godzinie 17:00, dlatego zachęcamy
              naszych gości do zajęcia dogodnych miejsc 10 minut wcześniej,
              czyli około godziny 16:50.
            </div>
          ),
        },
        {
          title:
            "Czy mogę liczyć na menu dostosowane do moich preferencji żywieniowych?",
          content: (
            <div>
              Z myślą o naszych gościach z indywidualnymi dietami przygotujemy
              dedykowane menu, które będzie dostosowane do ich potrzeb. Przy
              rejestracji obecności będziecie mieli możliwość wyboru spośród
              różnych opcji, takich jak:
              <ol>
                <li>tradycyjna,</li>
                <li>wegetariańska,</li>
                <li>bezglutenowa,</li>
                <li>wegańska,</li>
                <li>mleczko (dla najmłodszych gości).</li>
              </ol>
            </div>
          ),
        },
        {
          title: "Jaki jest wzór na prawo Ohma?",
          content: (
            <div>
              Wzór na prawo Ohma wyraża zależność między napięciem, natężeniem
              prądu i oporem w obwodzie elektrycznym. <br /> Ma on postać:{" "}
              <br />
              <br />
              U=I⋅R <br />
              <br />
              Gdzie: <br />U -napięcie elektryczne (w woltach, V),
              <br />
              I - natężenie prądu (w amperach, A),
              <br />
              R opór elektryczny (w omach, Ω). <br />
              <br />
              Prawo Ohma mówi, że napięcie w obwodzie elektrycznym jest
              proporcjonalne do iloczynu natężenia prądu i oporu.
            </div>
          ),
        },
        {
          title: "Nadal mam pytania. Jak najlepiej się z Wami skontaktować?",
          content: (
            <div>
              Najlepiej skontaktować się z nami pod numerami telefonu:
              <ul>
                <li>
                  <a href="tel: +48 500 071 538">+48 500 071 538 </a>
                  Patrycja
                </li>
                <li>
                  <a href="tel: +48 511 819 501">+48 511 819 501 </a>
                  Tomek
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
    // Additional sections as needed
  ];

  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const toggleAccordion = (compositeKey: string) => {
    setOpenAccordion(openAccordion === compositeKey ? null : compositeKey);
  };

  return (
    <div id="details">
      <div className="new-page">
        <div className="new-welcome-container">
          <div className="new-illustration details"></div>
          <div className="title2">SZCZEGÓŁY</div>
          <div className="subtitle subtitle-details">
            WSZYSTKO, CO MUSISZ WIEDZIEĆ
          </div>
        </div>
      </div>
      <div className="page2">
        <ArrowToLeft />
        <div style={{ marginBottom: "-120px" }}></div>
        <Agenda />
        <div className="section">
          {sectionsData.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <div className="section-title">
                <div className="display2">{section.sectionTitle}</div>
                <div className="display2sub">{section.sectionSubtitle}</div>
              </div>

              <div className="accordions-container">
                {section.accordions.map((accordion, accordionIndex) => {
                  const compositeKey = `${sectionIndex}-${accordionIndex}`;
                  return (
                    <Accordion
                      key={compositeKey}
                      title={accordion.title}
                      isOpen={openAccordion === compositeKey}
                      onToggle={() => toggleAccordion(compositeKey)}
                    >
                      {accordion.content}
                    </Accordion>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Details;
