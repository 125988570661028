import { IInvitation } from "../../components/IInvitation";
import { $axios } from "../client";

export async function getAllInvitations() {
  return await $axios.get<IInvitation[]>("/invitations");
}

export async function getInvitationById(id: string) {
  return await $axios.get<IInvitation>(`/invitations/${id}`);
}

export async function getInvitationByCode(code: string) {
  return await $axios.get<IInvitation>(`/invitations/code/${code}`);
}

export async function updateInvitationWithGuests(
  invitationId: string,
  guestsIds: string[]
) {
  return await $axios.put<IInvitation>(
    `/invitations/${invitationId}`,
    guestsIds
  );
}
