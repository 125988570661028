import ArrowToRight from "../../components/ArrowToRight";
import Detail from "../../components/Detail";

const CzarnyStawDetails: React.FC = () => {
  return (
    <>
      <Detail
        title="MIEJSCE"
        firstline="Czarny Staw"
        secondline="Nowy Adamów 3A"
        map="https://maps.app.goo.gl/6h3hjUnFiy8sXx838"
        textAlign={"left"}
      />
      <ArrowToRight />
      <Detail
        title="SZCZEGÓŁY"
        firstline="Zameldowanie: 14:00"
        secondline="Wymeldowanie: 12:00"
        thirdline="Śniadanie: 08:00"
        // map="https://maps.app.goo.gl/6h3hjUnFiy8sXx838"
        textAlign={"right"}
      />
    </>
  );
};

export default CzarnyStawDetails;
