export function formatDate(dateString: any): string {
  let date: Date;
  if (dateString instanceof Date) {
    date = dateString;
  } else if (typeof dateString === "string" || typeof dateString === "number") {
    date = new Date(dateString);
  } else {
    return "";
  }

  if (isNaN(date.getTime())) {
    return "";
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}
