import React, { useEffect, useState } from "react";
import Toggle from "../../Toggle";
import CTAButton from "../../CTA";
import { RsvpFamilyProps } from "../RsvpFamilyProps";
import { dietOptions, kidDietOptions, adultDietOptions } from "../dietOptions";
import { ToggleItem } from "../ToggleItem";
import Dropdown from "../../Dropdown";
import Typography from "../../Typography";
import RsvpSendConfirmationModal from "../RsvpSendConfirmationModal";
import { GuestRsvpDto } from "../GuestRsvpDto";
import SecondaryButton from "../../SecondaryButton";
import Song from "./Song";

const RsvpFamily: React.FC<RsvpFamilyProps> = ({
  toggles,
  sendForm,
  isEditing,
  setEditing,
}) => {
  const [updatedGuests, setUpdatedGuests] = useState<GuestRsvpDto[]>([]);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    // Populate initial state for guest diets if needed
    const initialGuests: GuestRsvpDto[] = [];
    if (!toggles) return;
    toggles.forEach((toggle) => {
      initialGuests.push({
        id: toggle.guest.id,
        name: toggle.guest.name,
        rsvp: toggle.isChecked,
        diet: "traditional",
      });
    });
    setUpdatedGuests(initialGuests);
  }, [toggles]);

  const [guests, setGuests] = useState<ToggleItem[]>(
    toggles ? toggles.map((toggle) => ({ ...toggle })) : [] // Check if toggles is provided
  );

  const handleToggle = (index: number) => {
    const newGuests = [...guests];
    newGuests[index].isChecked = !newGuests[index].isChecked;
    setGuests(newGuests);
    updatedGuests[index].rsvp = newGuests[index].isChecked;
  };

  if (!toggles) {
    return <div>Loading...</div>; // Placeholder for when toggles are not loaded yet
  }
  const kidDiet = Object.values(kidDietOptions);
  const adultDiet = Object.values(adultDietOptions);

  const getOptions = (guestType: string, index: number) => {
    if (guestType === "kid_under_three") {
      if (index !== undefined) {
        try {
          updatedGuests[index].diet = "milk";
        } catch {}
      }
      return kidDiet;
    } else {
      return adultDiet;
    }
  };

  const handleSelect = (option: string, index?: number) => {
    const selectedDiet = Object.keys(dietOptions).find(
      (key) => dietOptions[key as keyof typeof dietOptions] === option
    );
    if (index !== undefined) {
      updatedGuests[index].diet = selectedDiet?.toString() || "";
    }
  };

  return (
    <div>
      <div className="section-container">
        <Typography variant="BodyL bold">POTWIERDZENIE OBECNOŚCI</Typography>
      </div>
      <div className="section-container">
        <Typography variant="ParagraphM bold">
          Czy będziecie na weselu?
        </Typography>
        {guests.map((guest, index) => (
          <Toggle
            key={index}
            name={guest.guest.name}
            isChecked={guest.isChecked}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
      <div className="section-container">
        <Typography variant="ParagraphM bold">
          Jaką dietę preferujecie?
        </Typography>
        <div className="dropdown-container">
          {guests.map((guest, index) => (
            <div key={index}>
              <Typography variant="Caption">{guest.guest.name}</Typography>
              <Dropdown
                index={index}
                options={getOptions(guest.guest.type, index)}
                onSelect={handleSelect}
                isActive={guest.isChecked}
              />
            </div>
          ))}
        </div>
        <Song setTitle={setTitle} title={title} />
      </div>
      {isEditing ? (
        <div className="buttons">
          <CTAButton
            text="Zapisz zmiany"
            onClick={() => setConfirmationModalOpen(true)}
          />
          <SecondaryButton
            text="Anuluj"
            onClick={() => {
              setEditing && setEditing(false);
            }}
          />
        </div>
      ) : (
        <CTAButton
          text="Wyślij"
          onClick={() => setConfirmationModalOpen(true)}
        />
      )}
      <RsvpSendConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setConfirmationModalOpen(false);
          sendForm(updatedGuests, title);
          setEditing && setEditing(false);
        }}
      />
    </div>
  );
};

export default RsvpFamily;
