// Detail.tsx

import React from "react";
import "../styles/Detail.css";
import { NavLink } from "react-router-dom";
import Typography from "./Typography";

interface DetailProps {
  title: string;
  firstline: string;
  secondline?: string;
  thirdline?: string;
  map?: string;
  link?: string;
  textAlign: string;
}

const Detail: React.FC<DetailProps> = ({
  title,
  firstline,
  secondline,
  thirdline,
  map,
  link,
  textAlign,
}) => {
  return (
    <div className={`detail-container ${textAlign}`}>
      <div style={{ marginBottom: "8px" }}>
        <Typography variant="Display2">{title}</Typography>
      </div>
      <Typography variant="ParagraphM">{firstline}</Typography>
      {secondline && <Typography variant="ParagraphM">{secondline}</Typography>}
      {thirdline && <Typography variant="ParagraphM">{thirdline}</Typography>}
      {map && (
        <a
          href={map}
          // href={"geo:37.786971,-122.399677;u=35"}
          className="detail-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sprawdź na mapie
        </a>
      )}
      {link && (
        <NavLink
          to={link}
          className="detail-link"
          onClick={() => window.scrollTo(0, 0)}
        >
          Sprawdź swój nocleg
        </NavLink>
      )}
    </div>
  );
};

export default Detail;
