import React from "react";
import Typography from "../Typography";
import CTAButton from "../CTA";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../SecondaryButton";

interface SendRsvpStatusProps {
  status: "loading" | "success" | "failure" | null;
  setStatus: React.Dispatch<
    React.SetStateAction<"loading" | "success" | "failure" | null>
  >;
}

const SendRsvpStatus: React.FC<SendRsvpStatusProps> = ({
  status,
  setStatus,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {status === "loading" && <p>Sending...</p>}

      {status === "success" && (
        <div className="rsvp-status-container">
          <div className="rsvp-status sent-success"></div>
          <Typography variant="Title">DZIĘKUJEMY!</Typography>
          <Typography variant="BodyM">
            Twój formularz został pomyślnie wysłany! Jeśli chcesz coś zmienić,
            edytuj formularz.
          </Typography>
          <div className="buttons">
            <CTAButton
              text="Wróć na stronę główną"
              onClick={() => navigate("/")}
            />
            <SecondaryButton
              text="Podgląd formularza"
              onClick={() => {
                setStatus(null);
              }}
            ></SecondaryButton>
          </div>
        </div>
      )}

      {status === "failure" && (
        <div className="rsvp-status-container">
          <div className="rsvp-status sent-failure"></div>
          <Typography variant="Title">UPS!</Typography>
          <Typography variant="BodyM">
            Wystąpił problem podczas przesyłania formularza. Spróbuj ponownie
            lub zadzwoń do nas.
          </Typography>
          <Typography variant="BodyM">
            <a href="tel: +48 500 071 538">+48 500 071 538</a> Patrycja
            <br></br>
            <a href="tel: +48 511 819 501">+48 511 819 501</a> Tomek
          </Typography>
          <CTAButton text="Spróbuj ponownie" onClick={() => setStatus(null)} />
        </div>
      )}
    </div>
  );
};

export default SendRsvpStatus;
