import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CircularProgress,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import api from "../api";
import DownloadCard from "./DownloadCard";
import InvitationStatsCard from "./InvitationStatsCard";
import AccommodationStatsCard from "./AccommodationStatsCard";
import { IGuest } from "./IGuest";
import DietStatsCard from "./DietStatsCard";

const GuestsStatisticsCard: React.FC = () => {
  const [guests, setGuests] = useState<IGuest[]>();
  const [totalGuests, setTotalGuests] = useState<number>(0);
  const [rsvpYesCount, setRsvpYesCount] = useState<number>(0);
  const [rsvpNoCount, setRsvpNoCount] = useState<number>(0);
  const [rsvpNotYetCount, setRsvpNotYetCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [adultsCount, setAdultsCount] = useState<number>(0);
  const [kidsCount, setKidsCount] = useState<number>(0);
  const [serviceCount, setServiceCount] = useState<number>(0);
  const [kidsUnderThreeCount, setKidsUnderThreeCount] = useState<number>(0);

  const [totalCost, setTotalCost] = useState<number>(0);
  const [guestTypeCosts, setGuestTypeCosts] = useState<{
    [key: string]: number;
  }>({
    adults: 345,
    kids: 241.5,
    service: 241.5,
    kidsUnderThree: 0,
  });

  useEffect(() => {
    fetchGuests();
    setGuestTypeCosts({
      adults: 345,
      kids: 241.5,
      service: 241.5,
      kidsUnderThree: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGuests = async () => {
    try {
      const response = await api.guests.getAllGuests();
      if (response.status !== 200) {
        throw new Error("Failed to fetch");
      }
      const guestsData = response.data;
      setGuests(response.data);
      setTotalGuests(guestsData.length);

      const rsvpYes = guestsData.filter((guest) => guest.rsvp === true);
      const rsvpNo = guestsData.filter((guest) => guest.rsvp === false);
      const rsvpNotYet = guestsData.filter(
        (guest) => guest.rsvp === null || guest.rsvp === undefined
      );
      setRsvpYesCount(rsvpYes.length);
      setRsvpNoCount(rsvpNo.length);
      setRsvpNotYetCount(rsvpNotYet.length);

      const adults = rsvpYes.filter((guest) => guest.type === "adult");
      const kids = rsvpYes.filter((guest) => guest.type === "kid_over_three");
      const service = rsvpYes.filter((guest) => guest.type === "service");
      const kidsUnderThree = rsvpYes.filter(
        (guest) => guest.type === "kid_under_three"
      );
      setAdultsCount(adults.length);
      setKidsCount(kids.length);
      setServiceCount(service.length);
      setKidsUnderThreeCount(kidsUnderThree.length);

      const total =
        adults.length * guestTypeCosts.adults +
        kids.length * guestTypeCosts.kids +
        service.length * guestTypeCosts.service +
        kidsUnderThree.length * guestTypeCosts.kidsUnderThree;
      setTotalCost(total);
    } catch (error) {
      console.error("Error fetching guests data:", error);
    } finally {
      setLoading(false);
    }
  };

  const rsvpData = [
    { name: "Potwierdzili, że będą", value: rsvpYesCount },
    { name: "Potwierdzili, \n że ich nie będzie", value: rsvpNoCount },
    { name: "Nie odpowiedzieli jeszcze", value: rsvpNotYetCount },
  ];

  const guestTypeData = [
    { name: "Dorośli", value: adultsCount },
    { name: "Dzieci", value: kidsCount },
    { name: "Obsługa", value: serviceCount },
    { name: "Dzieci poniżej 3 lat", value: kidsUnderThreeCount },
  ];

  const COLORS = ["#00C49F", "#FF8042", "#FFBB28", "#0088FE"];

  return (
    <Box
      sx={{
        padding: "20px",
        borderRadius: "16px",
        margin: "auto",
        mt: 4,
        width: "90%",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} alignItems="stretch">
          {/* Card 1: RSVP Statistics Table */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                padding: "20px",
                borderRadius: "16px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">Statystyki RSVP</Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Łączna liczba gości</TableCell>
                      <TableCell>{totalGuests}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Potwierdzili, że będą</TableCell>
                      <TableCell>
                        {rsvpYesCount} (
                        {((rsvpYesCount / totalGuests) * 100).toFixed(1)}%)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Potwierdzili, że ich nie będzie</TableCell>
                      <TableCell>
                        {rsvpNoCount} (
                        {((rsvpNoCount / totalGuests) * 100).toFixed(1)}%)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Nie odpowiedzieli jeszcze</TableCell>
                      <TableCell>
                        {rsvpNotYetCount} (
                        {((rsvpNotYetCount / totalGuests) * 100).toFixed(1)}%)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>

          {/* Card 2: RSVP Pie Chart */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                padding: "20px",
                borderRadius: "16px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Legend layout="vertical" verticalAlign="bottom" />
                  <Pie
                    data={rsvpData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={({ name, percent }) =>
                      // `${name} ${(percent * 100).toFixed(0)}%`
                      `${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {rsvpData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </Grid>

          {/* Card 3: Guest Type Statistics Table */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                padding: "20px",
                borderRadius: "16px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant="h6">
                          Statystyki według typu gości:
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Dorośli</TableCell>
                      <TableCell>
                        {adultsCount} (
                        {((adultsCount / rsvpYesCount) * 100).toFixed(1)}%)
                      </TableCell>
                      <TableCell>
                        {adultsCount * guestTypeCosts.adults} PLN
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dzieci</TableCell>
                      <TableCell>
                        {kidsCount} (
                        {((kidsCount / rsvpYesCount) * 100).toFixed(1)}%)
                      </TableCell>
                      <TableCell>
                        {kidsCount * guestTypeCosts.kids} PLN
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dzieci poniżej 3 lat</TableCell>
                      <TableCell>
                        {kidsUnderThreeCount} (
                        {((kidsUnderThreeCount / rsvpYesCount) * 100).toFixed(
                          1
                        )}
                        %)
                      </TableCell>
                      <TableCell>
                        {kidsUnderThreeCount * guestTypeCosts.kidsUnderThree}{" "}
                        PLN
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Obsługa</TableCell>
                      <TableCell>
                        {serviceCount} (
                        {((serviceCount / rsvpYesCount) * 100).toFixed(1)}%)
                      </TableCell>
                      <TableCell>
                        {serviceCount * guestTypeCosts.service} PLN
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>Koszt całkowity</TableCell>
                      <TableCell>{totalCost.toFixed(2)} PLN</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>

          {/* Card 4: Guest Type Pie Chart */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                padding: "20px",
                borderRadius: "16px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Legend layout="vertical" verticalAlign="bottom" />

                  <Pie
                    data={guestTypeData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={
                      ({ name, percent }) => `${(percent * 100).toFixed(0)}%`
                      // `${name} ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {guestTypeData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <DownloadCard />
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <InvitationStatsCard />
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={370}>
              <AccommodationStatsCard guests={guests} />
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={370}>
              <DietStatsCard guests={guests} />
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default GuestsStatisticsCard;
