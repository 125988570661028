import React from "react";
import "../App.css";
import DateCountdown from "../components/DateCountdown";

export const Countdown = () => {
  return (
    <div className="page-container">
      <div className="page">
        <div style={{ fontSize: "80px" }}>
          <DateCountdown />
        </div>
      </div>
    </div>
  );
};

export default Countdown;
