import React, { useEffect, useState } from "react";
import CTAButton from "../../CTA";
import Typography from "../../Typography";
import api from "../../../api";
import { IGuest } from "../../IGuest";
import { useNavigate } from "react-router-dom";
import { Diet } from "../../../enums/rsvp/invitation.enum";
import { dietOptions } from "../dietOptions";
import { formatDate } from "../../../utils/formatDate";

interface RsvpPreviewProps {
  onEditing: (isEditing: boolean) => void;
}

const RsvpSinglePreview: React.FC<RsvpPreviewProps> = ({ onEditing }) => {
  const uuid = localStorage.getItem("uuid");
  const navigate = useNavigate();
  const [guests, setGuests] = useState<IGuest[]>();
  const [song, setSong] = useState<string | null>();

  useEffect(() => {
    const fetchGuests = async () => {
      if (uuid) {
        try {
          const response = await api.invitations.getInvitationById(uuid);
          setGuests(response.data.guests);
          setSong(response.data.song);
        } catch (error) {
          console.error("Failed to fetch guests:", error);
        }
      }
    };

    fetchGuests();
  }, [uuid]);

  function getDietOption(dietString: string | null): string | undefined {
    // Make sure the dietString is a valid enum key
    if (dietString !== null) {
      return dietOptions[dietString as Diet];
    }
    return "";
  }

  function getDiet(guest: IGuest): string | undefined {
    if (guest.rsvp) return getDietOption(guest.diet);
    else return "-";
  }
  return (
    <div>
      <div className="section-container">
        <Typography variant="BodyL bold">POTWIERDZENIE OBECNOŚCI</Typography>
        <div className="flex-date">
          <div className="italic-date">
            Wysłano:{" "}
            {guests !== undefined && guests[0] && guests[0].rsvpTime
              ? formatDate(guests[0].rsvpTime)
              : ""}
          </div>
          {/* <label className="third-button" onClick={() => onEditing(true)}>
            Edytuj
          </label> */}
        </div>
      </div>
      <div className="section-container">
        <Typography variant="ParagraphM bold">
          Czy będziesz na weselu?
        </Typography>
        {guests?.map((guest, index) => (
          <div key={index} className="flex-date">
            <Typography variant="ParagraphS">{guest.name}</Typography>
            <Typography variant="ParagraphS">
              {guest.rsvp === true ? "Tak" : ""}{" "}
              {guest.rsvp === false ? "Nie" : ""}{" "}
            </Typography>
          </div>
        ))}
      </div>
      <div className="section-container">
        <Typography variant="ParagraphM bold">
          Jaką dietę preferujesz?
        </Typography>
        <div className="rsvp-diet-container">
          {guests?.map((guest, index) => (
            <div key={index} className="rsvp-diet-small-container">
              <Typography variant="ParagraphS">{guest.name}</Typography>
              <Typography variant="ParagraphS">{getDiet(guest)}</Typography>
            </div>
          ))}
        </div>
      </div>
      {song && (
        <div className="section-container">
          <Typography variant="ParagraphM bold">
            Do jakiej piosenki chętnie zatańczysz?
          </Typography>
          <Typography variant="ParagraphS">{song}</Typography>
        </div>
      )}
      <CTAButton
        text="Wróć na stronę główną"
        onClick={() => {
          navigate("/");
        }}
      />
    </div>
  );
};

export default RsvpSinglePreview;
