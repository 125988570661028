// SearchTables.tsx

import React, { useState } from "react";
import "../../styles/SearchBar.css"; // Import the CSS file for the searcher
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { TableGuest } from "../../interfaces/tables/TableGuest";

interface SearchTablesProps {
  onSearch: (searchTerm: string) => void;
  selectedTable: number | null;
  tableGuestData: TableGuest[];
  setSelectedTable: React.Dispatch<React.SetStateAction<number | null>>;
}

const SearchTables: React.FC<SearchTablesProps> = ({
  onSearch,
  selectedTable,
  tableGuestData,
  setSelectedTable,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<
    { guest: string; tableNumber: number }[]
  >([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // Generate suggestions based on your data
    const newSuggestions = generateSuggestions(newSearchTerm);
    setSuggestions(newSuggestions);

    // Show suggestions if there are any and input is not empty
    setShowSuggestions(newSearchTerm.length > 0 && newSuggestions.length > 0);
  };

  const generateSuggestions = (
    term: string
  ): { guest: string; tableNumber: number }[] => {
    return tableGuestData
      .flatMap((tableGuest) => {
        // Check if guests is defined and is an array
        if (Array.isArray(tableGuest.guests)) {
          return tableGuest.guests
            .filter((guest) => guest.toLowerCase().includes(term.toLowerCase()))
            .map((guest) => ({ guest, tableNumber: tableGuest.tableNumber }));
        } else {
          return []; // Return an empty array if guests is undefined or not an array
        }
      })
      .slice(0, 5);
  };

  const handleSuggestionClick = (suggestion: {
    guest: string;
    tableNumber: number;
  }) => {
    setSearchTerm(suggestion.guest);
    setShowSuggestions(false);
    // onSearch(suggestion.guest); // Trigger search with selected suggestion
    setSelectedTable((prevSelectedTable) =>
      prevSelectedTable === suggestion.tableNumber
        ? prevSelectedTable
        : suggestion.tableNumber
    );
  };

  return (
    <div
      className={`search-container${
        showSuggestions && suggestions.length > 0 ? " suggestions-active" : ""
      }`}
    >
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Wpisz swoje imię i nazwisko"
        className="search-input"
      />
      {showSuggestions && suggestions.length > 0 && (
        <div className="suggestions-container">
          {suggestions.map((suggestion, index) => {
            // Find the position of the search term in the suggestion's guest name
            const matchIndex = suggestion.guest
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase());

            // Split the suggestion's guest name into three parts: before the match, the match itself, and after the match
            const beforeMatch = suggestion.guest.substring(0, matchIndex);
            const matchText = suggestion.guest.substring(
              matchIndex,
              matchIndex + searchTerm.length
            );
            const afterMatch = suggestion.guest.substring(
              matchIndex + searchTerm.length
            );

            return (
              <div
                key={index}
                className="suggestion"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {beforeMatch}
                <span className="bold">{matchText}</span>
                {afterMatch}
                {` | Stół ${suggestion.tableNumber}`}
              </div>
            );
          })}
        </div>
      )}
      <IconButton style={{ color: "white" }} className="icon-button">
        <SearchIcon />
      </IconButton>
    </div>
  );
};
export default SearchTables;
