import React, { useState, useEffect } from "react";
import RadioGroup from "../RadioGroup";
import CTAButton from "../../CTA";
import Dropdown from "../../Dropdown";
import { adultDietOptions } from "../dietOptions";
import { IGuest } from "../../IGuest";
import Typography from "../../Typography";
import RsvpSendConfirmationModal from "../RsvpSendConfirmationModal";
import { GuestRsvpDto } from "../GuestRsvpDto";
import SecondaryButton from "../../SecondaryButton";
import Song from "./Song";

interface RsvpPairProps {
  guests: IGuest[];
  sendForm: (updatedGuests: GuestRsvpDto[], song: string) => void;
  isEditing?: boolean;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RsvpPair: React.FC<RsvpPairProps> = ({
  guests,
  sendForm,
  isEditing,
  setEditing,
}) => {
  const [accPerson, setAccPerson] = useState("");
  const [additionalPerson, setAdditionalPerson] = useState<string>("true");
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [updatedGuests, setUpdatedGuests] = useState<GuestRsvpDto[]>([]);
  const [title, setTitle] = useState("");
  const [confirmed, setConfirmed] = useState<string>("true");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccPerson(e.target.value);
    setUpdatedGuests((prev) =>
      prev.map((guest, index) =>
        index === 1 ? { ...guest, name: e.target.value } : guest
      )
    );
  };

  const options = Object.values(adultDietOptions);

  const handleSelect = (option: string, index?: number) => {
    const selectedDiet = Object.keys(adultDietOptions).find(
      (key) => adultDietOptions[key as keyof typeof adultDietOptions] === option
    );
    if (index !== undefined) {
      setUpdatedGuests((prev) =>
        prev.map((guest, idx) =>
          idx === index ? { ...guest, diet: selectedDiet || "" } : guest
        )
      );
    }
  };

  const handleOptionChange = (optionValue: string) => {
    setAdditionalPerson(optionValue);
    setUpdatedGuests((prev) =>
      prev.map((guest, index) =>
        index === 1 ? { ...guest, rsvp: optionValue === "true" } : guest
      )
    );
  };

  const handleConfirmation = (optionValue: string) => {
    setConfirmed(optionValue);
    setUpdatedGuests((prev) =>
      prev.map((guest) => ({ ...guest, rsvp: optionValue === "true" }))
    );
  };

  const saveGuests = () => {
    setConfirmationModalOpen(true);
  };

  useEffect(() => {
    const mountedGuests: GuestRsvpDto[] = guests.map((guest) => ({
      id: guest.id,
      name: guest.name,
      rsvp: true,
      diet: "traditional",
    }));
    setUpdatedGuests(mountedGuests);
  }, [guests]);

  return (
    <div>
      <div className="section-container">
        <Typography variant="BodyL bold">POTWIERDZENIE OBECNOŚCI</Typography>
        {guests[0] && (
          <div style={{ marginTop: "4px" }}>
            <Typography variant="paragraphS">{guests[0].name}</Typography>
          </div>
        )}
      </div>
      <div className="section-container">
        <Typography variant="ParagraphM bold">
          Czy będziesz na weselu?
        </Typography>
        <div className="inp-container">
          <RadioGroup
            groupName="confirmation"
            options={[
              { value: "true", label: "Tak, będę" },
              { value: "false", label: "Nie, nie będzie mnie" },
            ]}
            onOptionChange={handleConfirmation}
          />
        </div>
      </div>

      {confirmed !== "false" && (
        <div>
          <div className="section-container">
            <Typography variant="ParagraphM bold">
              Czy przyjdziesz z osobą towarzyszącą?
            </Typography>
            <div className="inp-container">
              <RadioGroup
                groupName="alone"
                options={[
                  { value: "true", label: "Z osobą towarzyszącą" },
                  { value: "false", label: "Bez osoby towarzyszącej" },
                ]}
                onOptionChange={handleOptionChange}
              />
            </div>
          </div>

          {additionalPerson === "true" && (
            <div className="section-container">
              <Typography variant="ParagraphM bold">
                Dane osoby towarzyszącej{" "}
              </Typography>
              <Typography variant="ParagraphS" color="var(--Szary)">
                (opcjonalne)
              </Typography>
              <div className="inp-container">
                <input
                  type="text"
                  placeholder="Wpisz imię i nazwisko..."
                  value={accPerson}
                  onChange={handleInputChange}
                  className="custom-input"
                />
              </div>
            </div>
          )}
          <div className="section-container">
            <Typography variant="ParagraphM bold">
              Jaką dietę preferujesz?{" "}
            </Typography>
            <div className="dropdown-container">
              <div>
                <Typography variant="Caption">{guests[0].name}</Typography>
                <Dropdown options={options} index={0} onSelect={handleSelect} />
              </div>
              {additionalPerson === "true" && (
                <div>
                  <Typography variant="Caption">
                    {accPerson ? accPerson : "Osoba towarzysząca"}
                  </Typography>

                  <Dropdown
                    options={options}
                    index={1}
                    onSelect={handleSelect}
                  />
                </div>
              )}
            </div>
          </div>
          <Song setTitle={setTitle} title={title} />
        </div>
      )}
      {isEditing ? (
        <div className="buttons">
          <CTAButton text="Zapisz zmiany" onClick={() => saveGuests()} />
          <SecondaryButton
            text="Anuluj"
            onClick={() => {
              setEditing && setEditing(false);
            }}
          />
        </div>
      ) : (
        <CTAButton text="Wyślij" onClick={() => saveGuests()} />
      )}
      <RsvpSendConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setConfirmationModalOpen(false);
          sendForm(updatedGuests, title);
          setEditing && setEditing(false);
        }}
      />
    </div>
  );
};

export default RsvpPair;
