import React, { FC } from "react";

interface FlexGapProps {
  flexGrow?: number;
}

const FlexGap: FC<FlexGapProps> = ({ flexGrow = 1 }) => (
  <div style={{ flexGrow }} />
);

export default FlexGap;
