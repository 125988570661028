import { IGuest } from "../../components/IGuest";
import { GuestRsvpDto } from "../../components/rsvp/GuestRsvpDto";
import { TableGuest } from "../../interfaces/tables/TableGuest";
import { $axios } from "../client";

export async function getAllGuests() {
  return await $axios.get<IGuest[]>("/guests");
}

export async function getAllTables() {
  return await $axios.get<TableGuest[]>("/guests/tables");
}

export async function getGuestById(id: string) {
  return await $axios.get<IGuest>(`/guests/${id}`);
}

export async function updateGuestById(id: string, guest: IGuest) {
  return await $axios.put<IGuest>(`/guests/${id}`, guest);
}

export async function updateRsvpMultipleGuests(
  guests: GuestRsvpDto[],
  uuid: string | null,
  title: string
) {
  return await $axios.put<GuestRsvpDto[]>("/guests/rsvp/multiple", {
    guests,
    uuid,
    title,
  });
}
