import React, { RefObject, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "../styles/navbar.css";
import { IInvitation } from "./IInvitation";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginModal from "./home/LoginModal/LoginModal";
import api from "../api";
import { useAuth } from "../pages/AuthContext";

const Navbar: React.FC = () => {
  const uuid = localStorage.getItem("uuid");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const isRsvpPage = location.pathname === "/rsvp";
  const greetingRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const { logout: adminLogOut } = useAuth();

  function logout(): void {
    localStorage.removeItem("uuid");
    navigate("/");
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  function login(): void {
    setModalOpen(true);
  }

  const [invitation, setInvitation] = useState<IInvitation | null>(null);

  useEffect(() => {
    setShowLogoutConfirmation(false);
  }, []);

  useEffect(() => {
    if (uuid) {
      const fetchInvitationById = async (): Promise<void> => {
        try {
          const response = await api.invitations.getInvitationById(uuid);
          if (response.status === 200) {
            setInvitation(response.data);
          } else {
            console.error("Failed to fetch invitation:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching invitation:", error);
        }
      };
      fetchInvitationById();
    }
  }, [uuid]);

  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  function toggleLogoutConfirmation(): void {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  }

  function useOutsideClick<T extends HTMLElement>(
    ref: RefObject<T>,
    callback: () => void
  ): void {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, callback]);
  }
  useOutsideClick<HTMLDivElement>(greetingRef, () =>
    setShowLogoutConfirmation(false)
  );

  return (
    <nav className="navbar-container">
      <NavLink to="/" className={`logo ${isRsvpPage ? "rsvp" : ""}`}></NavLink>
      <div className="grid-item nav-links">
        <NavLink
          to="/dojazd"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : ""} ${isRsvpPage ? "rsvp" : ""}`
          }
        >
          Dojazd
        </NavLink>
        <NavLink
          to="/nocleg"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : ""} ${isRsvpPage ? "rsvp" : ""}`
          }
        >
          Nocleg
        </NavLink>
        <NavLink
          to="/szczegoly"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : ""} ${isRsvpPage ? "rsvp" : ""}`
          }
        >
          Szczegóły
        </NavLink>
        <NavLink
          to="/stoly"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : ""} ${isRsvpPage ? "rsvp" : ""}`
          }
        >
          Stoły
        </NavLink>
        <NavLink
          to="/zdjecia"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : ""} ${isRsvpPage ? "rsvp" : ""}`
          }
        >
          Zdjęcia
        </NavLink>
      </div>

      <div className="grid-item greeting">
        {token ? (
          <div
            className={`greeting-container ${isRsvpPage ? "rsvp" : ""}`}
            onClick={adminLogOut}
          >
            admin
          </div>
        ) : (
          <>
            {uuid ? (
              <div
                ref={greetingRef}
                className={`greeting-container ${isRsvpPage ? "rsvp" : ""}`}
                onClick={toggleLogoutConfirmation}
              >
                {invitation?.greeting}
                <div
                  className={`dropdown-icon-n  ${
                    showLogoutConfirmation ? "expanded" : ""
                  } ${isRsvpPage ? "rsvp" : ""}`}
                >
                  <ArrowDropDownIcon />
                </div>
                {showLogoutConfirmation && (
                  <div onClick={logout} className="logout-confirmation">
                    <LogoutIcon /> Wyloguj się
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`greeting-container ${isRsvpPage ? "rsvp" : ""}`}
                onClick={login}
              >
                Zaloguj się
              </div>
            )}
          </>
        )}
      </div>
      <LoginModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={"LOGOWANIE"}
        subtitle={"Zeskanuj kod QR, lub wpisz kod z zaproszenia!"}
      ></LoginModal>
    </nav>
  );
};

export default Navbar;
