import React, { useState } from "react";

import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "./AuthContext";

interface LoginResponse {
  token: string;
}

const LoginComponent: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>("");
  const { login } = useAuth();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await axios.post<LoginResponse>(
        // "http://localhost:3002/auth/login", //TODO: Move to api
        "https://przerywacz.pl/auth/login",
        { username, password }
      );

      const { token } = response.data;
      login(token); // Update auth context with the token
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setError("Incorrect username or password");
      } else {
        setError("Login failed");
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        <CardContent>
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Username"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
              Log in
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LoginComponent;
