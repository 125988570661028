import React from "react";
import AgendaEntry from "../components/AgendaEntry";
import styles from "../styles/Agenda.module.css";

const Agenda: React.FC = () => {
  return (
    <div className="section" style={{ marginTop: "64px" }}>
      <div className="section-title">
        <div className="display2">AGENDA</div>
        <div className="display2sub">SPRAWDŹ, CO CIĘ CZEKA</div>
      </div>
      <div className={styles.container}>
        <div className={styles.verticalLine}>
          <AgendaEntry title="CEREMONIA ZAŚLUBIN" hour="17:00" />
          <AgendaEntry title="ZDJĘCIA Z GOŚĆMI" hour="18:00" />
          <AgendaEntry title="TOAST ZA MŁODĄ PARĘ" hour="18:30" />
          <AgendaEntry title="PIERWSZY POSIŁEK" hour="19:00" />
          <AgendaEntry title="PIERWSZY TANIEC" hour="19:30" />
          <AgendaEntry title="TORT WESELNY" hour="21:00" />
          <AgendaEntry title="DRUGI POSIŁEK" hour="22:00" />
          <AgendaEntry title="SZTUCZNE OGNIE" hour="23:00" />
          <AgendaEntry title="TRZECI POSIŁEK" hour="23:30" />
          <AgendaEntry title="OCZEPINY" hour="00:00" />
          <AgendaEntry title="CZWARTY POSIŁEK" hour="02:00" />
          <AgendaEntry title="PIĄTY POSIŁEK" hour="03:30" />
        </div>
      </div>
    </div>
  );
};
export default Agenda;
