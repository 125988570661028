import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styles from "./LoginModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import QrIlustration from "../../../img/qr.svg";
import Typography from "../../Typography";
import Gap from "../../Gap";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import FlexGap from "../../FlexGap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  redirectTo?: string;
  onLoginSuccess?: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  redirectTo = "/rsvp",
  onLoginSuccess,
}) => {
  const [code, setCode] = useState(["", "", "", "", ""]);
  const [isValid, setIsValid] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const focusInput = () => {
      if (isOpen && inputRefs.current[0]) {
        setCode(["", "", "", "", ""]);
        setUserInteracted(false); // Reset user interaction state
        inputRefs.current[0]!.focus();
      } else {
        timeout = setTimeout(focusInput, 100);
      }
    };
    focusInput();
    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isValid && userInteracted) {
      submit();
      setUserInteracted(false); // Reset user interaction state to prevent multiple submissions
    }
    setWrongCode(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, userInteracted]);

  const [wrongCode, setWrongCode] = useState(true); //TODO: Change to false

  const submit = async () => {
    try {
      const response = await api.invitations.getInvitationByCode(code.join(""));
      const uuid = response.data.id;
      if (uuid) {
        localStorage.setItem("uuid", uuid);
        // setShowLoginSuccess(true);
        // setTimeout(() => {
        if (onLoginSuccess) {
          onLoginSuccess(); // Call the success handler to update Accomodation state
        }
        setCode(["", "", "", "", ""]);
        navigate(redirectTo);
        onClose();
        // }, 2000);
      } else {
        setWrongCode(true);
      }
    } catch {
      setWrongCode(true);
    }
  };

  const handleChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setUserInteracted(true); // User has interacted with the form
      const newCode = e.target.value.toUpperCase();
      const updatedCode = code.map((c, i) => (i === index ? newCode : c));

      setCode(updatedCode);

      if (/^[a-z0-9óąśłżźćńęÓĄŚŁŻŹĆŃĘ]$/i.test(newCode) && index < 4) {
        inputRefs.current[index + 1]?.focus();
      }
      setIsValid(
        updatedCode.join("").length === 5 &&
          updatedCode.every((char) =>
            /^[a-z0-9óąśłżźćńęÓĄŚŁŻŹĆŃĘ]$/i.test(char)
          )
      );

      // Scroll to the middle of the page
      const modalElement = document.getElementById("modal");
      if (modalElement) {
        modalElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

  const handleKeyDown =
    (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && !code[index]) {
        e.preventDefault();
        if (index > 0) {
          const updatedCode = [...code];
          updatedCode[index - 1] = "";
          setCode(updatedCode);
          inputRefs.current[index - 1]?.focus();
        }
      }
    };

  if (!isOpen) return null;

  return (
    <div>
      <Modal
        appElement={document.getElementById("root")!}
        isOpen={isOpen}
        onRequestClose={onClose}
        className={styles.container}
        overlayClassName={styles.overlay}
      >
        <img
          src={QrIlustration}
          alt="qr code illustration"
          className={styles.illustration}
        />

        <div id="modal" className={styles.content}>
          <CloseIcon className={styles.closeButton} onClick={onClose} />
          <Typography variant="Title">{title}</Typography>
          <Gap height={16} />
          <Typography variant="BodyS">{subtitle}</Typography>
          <FlexGap />
          <form style={{ display: "flex", justifyContent: "center" }}>
            <div className="PinContainer">
              {code.map((c, index) => (
                <input
                  className="PinInput"
                  key={index}
                  inputMode="text"
                  type="text"
                  value={c}
                  onChange={handleChange(index)}
                  onKeyDown={handleKeyDown(index)}
                  maxLength={1}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </div>
          </form>
          {wrongCode ? (
            <div className={styles.codeError}>
              <ErrorOutlineIcon />
              Błędny kod. Spróbuj ponownie
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default LoginModal;
