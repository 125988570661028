import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  TableSortLabel,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { IGuest } from "./IGuest";

interface GuestsTableProps {
  guests: IGuest[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenModal: (guest: IGuest) => void;
  decideColorForInvitationId: (invitationId: string) => string;
}

const GuestsTable: React.FC<GuestsTableProps> = ({
  guests,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenModal,
  decideColorForInvitationId,
}) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof IGuest>("order");

  const [rsvpFilter, setRsvpFilter] = useState<string>("all");
  const [typeFilter, setTypeFilter] = useState<string>("all");
  const [dietFilter, setDietFilter] = useState<string>("all");
  const [accomodationFilter, setAccomodationFilter] = useState<string>("all");
  const [songsOnly, setSongsOnly] = useState<boolean>(false);

  const handleRequestSort = (property: keyof IGuest) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRsvpFilterChange = (event: SelectChangeEvent<string>) => {
    setRsvpFilter(event.target.value as string);
  };

  const handleTypeFilterChange = (event: SelectChangeEvent<string>) => {
    setTypeFilter(event.target.value as string);
  };

  const handleDietFilterChange = (event: SelectChangeEvent<string>) => {
    setDietFilter(event.target.value as string);
  };

  const handleAccomodationFilterChange = (event: SelectChangeEvent<string>) => {
    setAccomodationFilter(event.target.value as string);
  };

  const handleSongsOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSongsOnly(event.target.checked);
  };

  const filteredGuests = guests.filter((guest) => {
    const matchesRsvp =
      rsvpFilter === "all" ||
      (rsvpFilter === "yes" && guest.rsvp === true) ||
      (rsvpFilter === "no" && guest.rsvp === false) ||
      (rsvpFilter === "null" && guest.rsvp === null);
    const matchesType = typeFilter === "all" || guest.type === typeFilter;
    const matchesDiet = dietFilter === "all" || guest.diet === dietFilter;
    const matchesAccomodation =
      accomodationFilter === "all" || guest.accomodation === accomodationFilter;
    const matchesSongsOnly = !songsOnly || guest.invitation.song;

    return (
      matchesRsvp &&
      matchesType &&
      matchesDiet &&
      matchesAccomodation &&
      matchesSongsOnly
    );
  });

  const sortedGuests = filteredGuests.slice().sort((a, b) => {
    if (orderBy === "rsvpTime") {
      const dateA = new Date(a.rsvpTime || 0).getTime();
      const dateB = new Date(b.rsvpTime || 0).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    } else if (orderBy === "order") {
      return order === "asc" ? a.order - b.order : b.order - a.order;
    }
    // else if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    // else if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  });

  const startRowIndex = page * rowsPerPage;

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const pad = (num: number): string => String(num).padStart(2, "0");

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${day}.${month}.${year} (${hours}:${minutes})`;
  };

  const formatGuestType = (type: string): string => {
    switch (type) {
      case "adult":
        return "dorosły";
      case "kid_over_three":
        return "dziecko powyżej 3. lat";
      case "kid_under_three":
        return "dziecko poniżej 3. lat";
      case "service":
        return "obsługa";
    }
    return "";
  };

  const formatDiet = (diet: string | null): string => {
    switch (diet) {
      case "traditional":
        return "tradycyjna";
      case "vegan":
        return "wegańska";
      case "vegetarian":
        return "wegetariańska";
      case "glutenfree":
        return "bezglutenowa";
      case "milk":
        return "mleczko";
    }
    return "-";
  };

  const formatSong = (song: string | null): string => {
    if (song !== null) {
      return song.length > 10 ? `${song.substring(0, 10)}...` : song;
    } else {
      return "-";
    }
  };

  const formatAccommodation = (accommodation: string | null): string => {
    switch (accommodation) {
      case "czarny_staw":
        return "Czarny Staw";
      case "jesienna":
        return "Jesienna";
      case "home":
        return "Dom";
      case "radwanska":
        return "Radwańska";
    }
    return "-";
  };

  return (
    <TableContainer component={Paper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Rsvp</InputLabel>
          <Select
            value={rsvpFilter}
            onChange={handleRsvpFilterChange}
            label="Rsvp"
          >
            <MenuItem value="all">Wszystkie</MenuItem>
            <MenuItem value="yes">Tak</MenuItem>
            <MenuItem value="no">Nie</MenuItem>
            <MenuItem value="null">-</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 150 }}>
          <InputLabel>Typ</InputLabel>
          <Select
            value={typeFilter}
            onChange={handleTypeFilterChange}
            label="Typ"
          >
            <MenuItem value="all">Wszystkie</MenuItem>
            <MenuItem value="adult">Dorosły</MenuItem>
            <MenuItem value="kid_over_three">Dziecko powyżej 3. lat</MenuItem>
            <MenuItem value="kid_under_three">Dziecko poniżej 3. lat</MenuItem>
            <MenuItem value="service">Obsługa</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 180 }}>
          <InputLabel>Dieta</InputLabel>
          <Select
            value={dietFilter}
            onChange={handleDietFilterChange}
            label="Dieta"
          >
            <MenuItem value="all">Wszystkie</MenuItem>
            <MenuItem value="traditional">Tradycyjna</MenuItem>
            <MenuItem value="vegan">Wegańska</MenuItem>
            <MenuItem value="vegetarian">Wegetariańska</MenuItem>
            <MenuItem value="gluten_free">Bezglutenowa</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Nocleg</InputLabel>
          <Select
            value={accomodationFilter}
            onChange={handleAccomodationFilterChange}
            label="Nocleg"
          >
            <MenuItem value="all">Wszystkie</MenuItem>
            <MenuItem value="czarny_staw">Czarny Staw</MenuItem>
            <MenuItem value="jesienna">Jesienna</MenuItem>
            <MenuItem value="home">dom</MenuItem>
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox checked={songsOnly} onChange={handleSongsOnlyChange} />
          }
          label="Tylko z piosenkami"
        />
      </div>
      <Table aria-label="guests table">
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-head": {
                fontWeight: "bold",
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <TableCell>Lp.</TableCell>
            <TableCell>Imię i nazwisko</TableCell>
            <TableCell>Typ</TableCell>
            <TableCell>RSVP</TableCell>
            <TableCell>Dieta</TableCell>
            <TableCell>Piosenka</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "rsvpTime"}
                direction={orderBy === "rsvpTime" ? order : "asc"}
                onClick={() => handleRequestSort("rsvpTime")}
              >
                Data RSVP
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "accomodation"}
                direction={orderBy === "accomodation" ? order : "asc"}
                onClick={() => handleRequestSort("accomodation")}
              >
                Nocleg
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "table"}
                direction={orderBy === "table" ? order : "asc"}
                onClick={() => handleRequestSort("table")}
              >
                Stół
              </TableSortLabel>
            </TableCell>
            <TableCell>Szczegóły</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGuests
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((guest, index) => (
              <TableRow
                key={guest.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  backgroundColor: decideColorForInvitationId(
                    guest.invitation.id
                  ),
                }}
              >
                <TableCell component="th" scope="row">
                  {startRowIndex + index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {guest.name}
                </TableCell>
                <TableCell>{formatGuestType(guest.type)}</TableCell>
                <TableCell>
                  {guest.rsvp === null ? "-" : guest.rsvp ? "tak" : "nie"}
                </TableCell>
                <TableCell>{formatDiet(guest.diet)}</TableCell>
                <Tooltip title={guest.invitation.song} arrow>
                  <TableCell>{formatSong(guest.invitation.song)}</TableCell>
                </Tooltip>
                <TableCell>
                  {guest.rsvpTime
                    ? formatDate(guest.rsvpTime.toLocaleString())
                    : ""}
                </TableCell>
                <TableCell>{formatAccommodation(guest.accomodation)}</TableCell>
                <TableCell>{guest.table}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenModal(guest)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        sx={{
          ".MuiTablePagination-toolbar": { justifyContent: "center" },
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            { m: 0 },
        }}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredGuests.length} // Update count to filtered guests
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default GuestsTable;
