import React from "react";
import Modal from "react-modal";
import "../../styles/RsvpSendConfirmationModal.css";
import Typography from "../Typography";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CTAButton from "../CTA";
import PlaneIllustration from "../../img/plane.svg";
import SecondaryButton from "../SecondaryButton";

interface RsvpSendConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const RsvpSendConfirmationModal: React.FC<RsvpSendConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <div>
      <Modal
        appElement={document.getElementById("root")!}
        isOpen={isOpen}
        onRequestClose={onClose}
        style={{
          overlay: {
            backgroundColor: "rgba(96, 96, 96, 0.68)",
          },
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
            borderColor: "rgba(0, 0, 0, 0)",
            width: "328px",
            height: "381px",
            borderRadius: "4px",
            color: "white",
            padding: "0px",
            zIndex: 1000,
          },
        }}
      >
        <div className="rsvp-modal-close-button">
          <IconButton style={{ color: "white" }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="send-modal-container">
          <div className="send-modal-content">
            <div className="plane-illustration">
              <img src={PlaneIllustration} alt="Plane" />
            </div>
            <Typography variant="Title">WYSŁAĆ FORMULARZ?</Typography>
            <div className="buttons">
              <CTAButton onClick={onConfirm} text="Wyślij" />
              <SecondaryButton onClick={onClose} text="Anuluj" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RsvpSendConfirmationModal;
