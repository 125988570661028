import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IGuest } from "./IGuest";

export interface AccomodationStatsCardProps {
  guests: IGuest[] | undefined;
}

const AccommodationStatsCard: React.FC<AccomodationStatsCardProps> = ({
  guests,
}) => {
  const [jesienna, setJesienna] = useState<number>();
  const [czarnyStaw, setCzarnyStaw] = useState<number>();
  const [dom, setDom] = useState<number>();
  const [radwanska, setRadwanska] = useState<number>();

  useEffect(() => {
    setJesienna(
      guests?.filter((guest) => guest.accomodation === "jesienna").length
    );
    setCzarnyStaw(
      guests?.filter((guest) => guest.accomodation === "czarny_staw").length
    );
    setDom(guests?.filter((guest) => guest.accomodation === "home").length);
    setRadwanska(
      guests?.filter((guest) => guest.accomodation === "radwanska").length
    );
  }, [guests]);

  return (
    <Card
      sx={{
        padding: "20px",
        borderRadius: "16px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Statystyki według noclegu:</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Jesienna</TableCell>
              <TableCell>{jesienna}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dom</TableCell>
              <TableCell>{dom}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Czarny Staw</TableCell>
              <TableCell>{czarnyStaw}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Radwańska</TableCell>
              <TableCell>{radwanska}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AccommodationStatsCard;
