import React, { useEffect, useState } from "react";
import "../../styles/RadioButton.css";

interface RadioGroupProps {
  groupName: string;
  options: { value: string; label: string }[];
  onOptionChange: (optionValue: string) => void;
  initialValue?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  groupName,
  options,
  onOptionChange,
  initialValue,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionValue = event.target.value;
    setSelectedValue(optionValue);
    onOptionChange(optionValue);
  };

  useEffect(() => {
    const optionValue = options.length > 0 ? options[0].value : "";
    setSelectedValue(optionValue);
    onOptionChange(optionValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (initialValue !== undefined) {
      setSelectedValue(initialValue);
      onOptionChange(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <div>
      <div className="form-radio">
        {options.map((option) => (
          <label
            key={option.value}
            className={`radioButton-control ${
              selectedValue === option.value ? "checked" : ""
            }`}
          >
            <div className="label">
              <div className="paragraphS">{option.label}</div>
            </div>
            <input
              type="radio"
              name={groupName}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={handleRadioChange}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
