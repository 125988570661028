import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Stack,
} from "@mui/material";
import api from "../api";
import { IInvitation } from "./IInvitation";

const InvitationStatsCard: React.FC = () => {
  const [invitations, setInvitations] = useState<IInvitation[]>([]);

  useEffect(() => {
    const fetchInvitations = async () => {
      const response = await api.invitations.getAllInvitations();
      const data: IInvitation[] = response.data;
      setInvitations(data);
    };

    fetchInvitations();
  }, []);

  const rsvpTrueCount = invitations.filter((invitation) =>
    invitation.guests.some((guest) => guest.rsvp === true)
  ).length;

  return (
    <Card
      sx={{
        padding: "20px",
        borderRadius: "16px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          Statystyki zaproszeń:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Dane do tortu, oraz prezentów na wyjście
        </Typography>
      </CardContent>
      <CardActions>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Typography variant="h6" component="div">
            Liczba zaproszeń z potwierdzonym RSVP: {rsvpTrueCount}
          </Typography>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default InvitationStatsCard;
