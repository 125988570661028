import { Diet } from "../../enums/rsvp/invitation.enum";

export const dietOptions = {
  [Diet.Traditional]: "Tradycyjną",
  [Diet.Vegan]: "Wegańską",
  [Diet.Vegetarian]: "Wegetariańską",
  [Diet.GlutenFree]: "Bezglutenową",
  [Diet.Milk]: "Mleczko",
};

export const adultDietOptions = {
  [Diet.Traditional]: "Tradycyjną",
  [Diet.Vegan]: "Wegańską",
  [Diet.Vegetarian]: "Wegetariańską",
  [Diet.GlutenFree]: "Bezglutenową",
};

export const kidDietOptions = {
  [Diet.Milk]: "Mleczko",
};

export const allDietOptions = {
  [Diet.Traditional]: "Tradycyjna",
  [Diet.Vegan]: "Wegańska",
  [Diet.Vegetarian]: "Wegetariańska",
  [Diet.GlutenFree]: "Bezglutenowa",
  [Diet.Milk]: "Mleczko",
};
