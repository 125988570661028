import React from "react";
import Modal from "react-modal";
import styles from "./LeaveModal.module.css";
import Typography from "../../Typography";
import CloseIcon from "@mui/icons-material/Close";
import CTAButton from "../../CTA";
import Doors from "../../../img/doors.svg";
import SecondaryButton from "../../SecondaryButton";

interface RsvpSendConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLeave: () => void;
}

const LeaveModal: React.FC<RsvpSendConfirmationModalProps> = ({
  isOpen,
  onClose,
  onLeave,
}) => {
  return (
    <div>
      <Modal
        appElement={document.getElementById("root")!}
        isOpen={isOpen}
        onRequestClose={onClose}
        className={styles.container}
        overlayClassName={styles.overlay}
      >
        <CloseIcon className={styles.closeButton} onClick={onClose} />
        <img
          src={Doors}
          alt="Doors illustration"
          className={styles.illustration}
        />
        <div className={styles.content}>
          <Typography variant="Title">OPUŚCIĆ STRONĘ?</Typography>
          <Typography variant="BodyS">
            Wprowadzone dane nie <br />
            zostaną zapisane
          </Typography>
        </div>
        <div className={styles.buttons}>
          <CTAButton onClick={onLeave} text="Opuść stronę" />
          <SecondaryButton onClick={onClose} text="Anuluj" />
        </div>
      </Modal>
    </div>
  );
};

export default LeaveModal;
