import React, { useState, useEffect } from "react";
import "../App.css";
import ArrowToLeft from "../components/ArrowToLeft";
import LoginModal from "../components/home/LoginModal/LoginModal";
import api from "../api";
import { IInvitation } from "../components/IInvitation";
import CzarnyStawDetails from "../sections/accommodation/CzarnyStawDetails";
import OwnHomeDetails from "../sections/accommodation/OwnHome";
// import Banner from "../components/Banner/Banner";

export const Accomodation = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasUuid, setHasUuid] = useState(false); // New state to track if uuid exists
  const [invitation, setInvitation] = useState<IInvitation | null>(null);

  useEffect(() => {
    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      fetchInvitationById(uuid);
      setHasUuid(true); // If uuid exists, show accommodation details
    } else {
      setModalOpen(true); // If uuid doesn't exist, show the login modal
    }
  }, []);

  const fetchInvitationById = async (uuid: string): Promise<void> => {
    try {
      const response = await api.invitations.getInvitationById(uuid);
      if (response.status === 200) {
        setInvitation(response.data);
      } else {
        console.error("Failed to fetch invitation:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching invitation:", error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLoginSuccess = () => {
    setHasUuid(true); // Update the hasUuid state
    setModalOpen(false); // Close the modal
    const uuid = localStorage.getItem("uuid");
    fetchInvitationById(uuid || "");
  };

  const renderAccomodationDetails = () => {
    switch (invitation?.guests[0]?.accomodation) {
      case "czarny_staw":
        return <CzarnyStawDetails />;
      case "jesienna":
        return <CzarnyStawDetails />;
      default:
        return <OwnHomeDetails />;
    }
  };

  return (
    <div id="accomodation">
      <div className="new-page">
        <div className="new-welcome-container">
          <div className="new-illustration accomodation"></div>
          <div className="title2">NOCLEG</div>
          <div className="subtitle subtitle-accomodation">
            SPRAWDŹ, GDZIE ŚPISZ
          </div>
        </div>
      </div>
      <div className="page-content">
        {hasUuid ? (
          <div className="arrow-container">
            <ArrowToLeft />
            {renderAccomodationDetails()}
          </div>
        ) : (
          <LoginModal
            isOpen={isModalOpen}
            onClose={closeModal}
            title={"LOGOWANIE"}
            subtitle={"Zeskanuj kod QR, lub wpisz kod z zaproszenia!"}
            redirectTo="/nocleg"
            onLoginSuccess={handleLoginSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default Accomodation;
