import React, { FC } from "react";

interface GapProps {
  height: number;
}

const Gap: FC<GapProps> = ({ height }) => (
  <div style={{ height: `${height}px` }} />
);

export default Gap;
