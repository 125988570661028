// PrivateRoute.tsx
import React from "react";
import { useAuth } from "./AuthContext";
import LoginComponent from "./LoginComponent";

type PrivateRouteProps = {
  component: React.ComponentType<any>;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <Component {...rest} /> : <LoginComponent />;
};

export default PrivateRoute;
