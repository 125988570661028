import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import api from "../api";
import { IGuest } from "./IGuest";

const DownloadCard: React.FC = () => {
  const fetchData = async (filename: string) => {
    let data: any[] = [];

    switch (filename) {
      case "piosenki":
        const invitations = await api.invitations.getAllInvitations();
        data = invitations.data
          .map((invitation) => ({ name: invitation.song }))
          .filter((item) => item.name);
        break;

      case "winietki":
        const guests = await api.guests.getAllGuests();
        const filteredGuests = guests.data.filter((guest) => guest.rsvp);
        data = filteredGuests.map((guest) => ({ name: guest.name }));
        break;

      case "stoly":
        const allGuests = await api.guests.getAllGuests();
        const filteredAndSortedGuests = allGuests.data
          .filter(
            (guest): guest is Omit<IGuest, "table"> & { table: number } =>
              guest.table != null
          )
          .sort((a, b) => a.table - b.table);
        data = filteredAndSortedGuests.map((guest) => ({
          name: `${guest.table}., ${guest.name}`,
        }));
        break;

      default:
        throw new Error("Invalid filename");
    }

    return data;
  };

  const downloadCSV = async (filename: string) => {
    try {
      const data = await fetchData(filename);
      const csvData = convertToCSV(data);
      const csvWithBOM = "\uFEFF" + csvData;
      const blob = new Blob([csvWithBOM], { type: "text/csv; charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${filename}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const convertToCSV = (data: any[]) => {
    return data.map((item) => [item.name].join(",")).join("\n");
  };

  return (
    <Card
      sx={{
        padding: "20px",
        borderRadius: "16px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          Pobieranie plików do druku:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Wybierz plik do pobrania:
        </Typography>
      </CardContent>
      <CardActions>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Button
            aria-label="Pobierz winietki"
            onClick={() => downloadCSV("winietki")}
          >
            <FileDownloadIcon />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Pobierz winietki
            </Typography>
          </Button>
          <Button
            aria-label="Pobierz piosenki"
            onClick={() => downloadCSV("piosenki")}
          >
            <FileDownloadIcon />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Pobierz piosenki
            </Typography>
          </Button>
          <Button
            aria-label="Pobierz stoły"
            onClick={() => downloadCSV("stoly")}
          >
            <FileDownloadIcon />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Pobierz stoły
            </Typography>
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default DownloadCard;
