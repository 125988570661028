import React from "react";
import Typography from "../../Typography";

interface SongProps {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  copy?: string;
}

const Song: React.FC<SongProps> = ({ setTitle, title, copy }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  return (
    <div className="section-container" style={{ marginTop: "64px" }}>
      <Typography variant="ParagraphM bold">
        {copy ? copy : "Do jakiej piosenki chętnie zatańczycie?"}
      </Typography>
      <Typography variant="ParagraphS" color="var(--Szary)">
        (opcjonalne)
      </Typography>
      <div className="inp-container">
        <input
          type="text"
          placeholder="Wpisz tytuł i wykonawcę piosenki"
          value={title}
          onChange={handleInputChange}
          className="custom-input"
        />
      </div>
    </div>
  );
};
export default Song;
