import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { IGuest } from "./IGuest";
import api from "../api";
import styles from "../styles/GuestModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "../components/Typography";
import RadioGroup from "./rsvp/RadioGroup";
import { allDietOptions } from "./rsvp/dietOptions";
import Dropdown from "./Dropdown";
import { guestTypeOptions } from "./rsvp/typeOptions";
import { accomodationTypeOptions } from "./rsvp/accomodationOptions";
import CTAButton from "./CTA";
import SecondaryButton from "./SecondaryButton";
import { GuestType } from "../enums/rsvp/guest-type.enum";
import { Diet } from "../enums/rsvp/invitation.enum";
import { Accomodation } from "../enums/rsvp/accomodation.enum";

Modal.setAppElement("#root");

interface GuestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (guestData: IGuest) => void;
  guestId: string;
}

const GuestModal: React.FC<GuestModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  guestId,
}) => {
  const [updatedGuest, setUpdatedGuest] = useState<IGuest | undefined>();

  useEffect(() => {
    if (guestId) {
      fetchGuest(guestId);
    }
  }, [guestId]);

  const fetchGuest = async (id: string) => {
    try {
      const response = await api.guests.getGuestById(id);
      setUpdatedGuest(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching guest data:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedGuest((prevGuest) =>
      prevGuest ? { ...prevGuest, [name]: value } : undefined
    );
  };

  const handleOptionChange = (optionValue: string, name: string) => {
    setUpdatedGuest((prevGuest) =>
      prevGuest ? { ...prevGuest, [name]: optionValue } : undefined
    );
  };

  const handleRsvp = (optionValue: string, name: string) => {
    const booleanValue = optionValue === "true";
    setUpdatedGuest((prevGuest) =>
      prevGuest ? { ...prevGuest, [name]: booleanValue } : undefined
    );
  };

  const handleSelect = (option: string, index?: number, name?: string) => {
    if (name === "type") {
      // Find the enum key that corresponds to the selected label
      const selectedType = Object.keys(guestTypeOptions).find(
        (key) => guestTypeOptions[key as GuestType] === option
      ) as GuestType;

      setUpdatedGuest((prevGuest) =>
        prevGuest ? { ...prevGuest, [name]: selectedType } : undefined
      );
    } else if (name === "diet") {
      // Find the enum key that corresponds to the selected label
      const selectedDiet = Object.keys(allDietOptions).find(
        (key) => allDietOptions[key as Diet] === option
      ) as Diet;

      setUpdatedGuest((prevGuest) =>
        prevGuest ? { ...prevGuest, [name]: selectedDiet } : undefined
      );
    } else if (name === "accomodation") {
      // Find the enum key that corresponds to the selected label
      const selectedType = Object.keys(accomodationTypeOptions).find(
        (key) => accomodationTypeOptions[key as Accomodation] === option
      ) as Accomodation;

      setUpdatedGuest((prevGuest) =>
        prevGuest ? { ...prevGuest, [name]: selectedType } : undefined
      );
    } else if (name) {
      handleOptionChange(option, name);
    }
  };

  const saveGuest = async () => {
    if (updatedGuest) {
      try {
        await api.guests.updateGuestById(updatedGuest.id, updatedGuest);
        onSubmit(updatedGuest);
        onClose();
      } catch (error) {
        console.error("Error updating guest:", error);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Update Guest"
      className={styles.container}
      overlayClassName={styles.overlay}
    >
      <div id="modal" className={styles.content}>
        <CloseIcon className={styles.closeButton} onClick={onClose} />
        <div className="inp-container">
          <Typography variant="Caption">Imię i nazwisko</Typography>
          <input
            type="text"
            placeholder="Wpisz imię i nazwisko..."
            name="name"
            value={updatedGuest?.name || ""}
            onChange={handleInputChange}
            className="custom-input"
          />
        </div>
        <div className="inp-container">
          <Typography variant="Caption">Typ</Typography>
          <Dropdown
            options={Object.values(guestTypeOptions)}
            initialValue={
              updatedGuest?.type !== undefined
                ? guestTypeOptions[updatedGuest.type as GuestType]
                : Object.values(guestTypeOptions)[0]
            }
            onSelect={(option) => handleSelect(option, undefined, "type")}
          />
        </div>
        <div className="inp-container">
          <Typography variant="Caption">RSVP</Typography>
          <RadioGroup
            groupName="rsvp"
            options={[
              { value: "true", label: "Tak" },
              { value: "false", label: "Nie" },
            ]}
            onOptionChange={(value) => handleRsvp(value, "rsvp")}
            initialValue={updatedGuest?.rsvp ? "true" : "false"}
          />
        </div>
        <div className="inp-container">
          <Typography variant="Caption">Dieta</Typography>
          <Dropdown
            options={Object.values(allDietOptions)}
            initialValue={
              updatedGuest?.diet !== undefined
                ? allDietOptions[updatedGuest.diet as Diet]
                : Object.values(allDietOptions)[0]
            }
            onSelect={(option) => handleSelect(option, undefined, "diet")}
          />
        </div>
        <div className="inp-container">
          <Typography variant="Caption">Stół</Typography>
          <input
            type="text"
            name="table"
            value={updatedGuest?.table || ""}
            onChange={handleInputChange}
            className="custom-input"
          />
        </div>
        <div className="inp-container">
          <Typography variant="Caption">Nocleg</Typography>
          <Dropdown
            options={Object.values(accomodationTypeOptions)}
            initialValue={
              updatedGuest?.accomodation !== undefined
                ? accomodationTypeOptions[
                    updatedGuest.accomodation as Accomodation
                  ]
                : Object.values(accomodationTypeOptions)[0]
            }
            onSelect={(option) =>
              handleSelect(option, undefined, "accomodation")
            }
          />
        </div>
        <div className="buttons">
          <CTAButton text="Zapisz zmiany" onClick={saveGuest} />
          <SecondaryButton text="Anuluj" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default GuestModal;
