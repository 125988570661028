import React, { useState, useEffect } from "react";
import { Invitation } from "../enums/rsvp/invitation.enum";
import RsvpFamily from "../components/rsvp/forms/Family";
import RsvpPair from "../components/rsvp/forms/Pair";
import { NavLink, useBlocker } from "react-router-dom";
import RsvpSingle from "../components/rsvp/forms/Single";
import { IGuest } from "../components/IGuest";
import SendRsvpStatus from "../components/rsvp/SendRsvp";
import api from "../api";
import { IInvitation } from "../components/IInvitation";
import { GuestRsvpDto } from "../components/rsvp/GuestRsvpDto";
import RsvpFamilyPreview from "../components/rsvp/previews/FamilyPreview";
import RsvpPairPreview from "../components/rsvp/previews/PairPreview";
import LeaveModal from "../components/rsvp/LeaveModal/LeaveModal";
import RsvpSinglePreview from "../components/rsvp/previews/SinglePreview";

export const Rsvp: React.FC = () => {
  const uuid = localStorage.getItem("uuid");

  const [invitation, setInvitation] = useState<Invitation>(Invitation.Family);
  const [guests, setGuests] = useState<IGuest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = React.useState<
    "loading" | "success" | "failure" | null
  >(null);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isBlocked, setBlocked] = useState<boolean>(false);
  let blocker = useBlocker(isEditing);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlocked(true);
    }
  }, [blocker]);

  const fetchInvitations = async (uuid: string | null) => {
    if (uuid) {
      try {
        const response = await api.invitations.getInvitationById(uuid);
        const data: IInvitation = response.data;
        if (data.guests) {
          setGuests(data.guests as IGuest[]);
          setLoading(false);
        }
        if (data.type) {
          setInvitation(data.type as Invitation);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchInvitations(uuid);
  }, [uuid]);

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);

  const handleCtaClick = async (guests: GuestRsvpDto[], title: string) => {
    try {
      const res = await api.guests.updateRsvpMultipleGuests(
        guests,
        uuid,
        title
      );
      if (res.status === 200) {
        setStatus("success");
      }
    } catch (error) {
      setStatus("failure");
    }
  };

  const proceed = () => {
    if (blocker.proceed) {
      blocker.proceed();
    }
  };
  const renderInvitationComponent = (invitation: Invitation) => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (status !== null) {
      return <SendRsvpStatus status={status} setStatus={setStatus} />;
    }

    switch (invitation) {
      case Invitation.Family:
        return guests.length > 0 ? (
          <div>
            {guests[0].rsvp !== null && !isEditing ? (
              <RsvpFamilyPreview onEditing={setEditing} />
            ) : (
              <RsvpFamily
                toggles={guests.map((guest) => ({ guest, isChecked: true }))}
                isEditing={isEditing}
                setEditing={setEditing}
                sendForm={handleCtaClick}
              />
            )}
          </div>
        ) : (
          <div>
            Nie jesteś zalogowany. <NavLink to={"/login"}>Zaloguj się</NavLink>,
            aby przejść dalej
          </div>
        );
      case Invitation.Pair:
        return (
          <div>
            {guests[0].rsvp !== null && !isEditing ? (
              <RsvpPairPreview onEditing={setEditing} />
            ) : (
              <RsvpPair
                guests={guests}
                isEditing={isEditing}
                setEditing={setEditing}
                sendForm={handleCtaClick}
              />
            )}
          </div>
        );
      case Invitation.Single:
        return (
          <div>
            {guests[0].rsvp !== null && !isEditing ? (
              <RsvpSinglePreview onEditing={setEditing} />
            ) : (
              <RsvpSingle guest={guests[0]} sendForm={handleCtaClick} />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="form-container">
      {renderInvitationComponent(invitation)}
      <LeaveModal
        isOpen={isBlocked}
        onClose={() => setBlocked(false)}
        onLeave={() => proceed()}
      />
    </div>
  );
};

export default Rsvp;
