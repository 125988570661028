import React, { useEffect, useRef } from "react";
import "../styles/Accordion.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  const accordionRef = useRef<HTMLDivElement>(null); // Create a ref for the accordion

  useEffect(() => {
    const offset = 20; // Offset in pixels

    if (isOpen && accordionRef.current) {
      const elementPosition =
        accordionRef.current.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [isOpen]);

  return (
    <div ref={accordionRef} className="accordion">
      {" "}
      {/* Attach the ref to the accordion div */}
      <div className="accordion-header" onClick={onToggle}>
        <div className="accordion-title">{title}</div>
        <div className={`accordion-icon ${isOpen ? "open" : ""}`}>
          <ArrowDropDownIcon />
        </div>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export default Accordion;
