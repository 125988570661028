import React, { useState, useEffect, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import "../styles/dropdown.css";

interface DropdownProps {
  index?: number;
  options: string[];
  onSelect: (option: string, index?: number) => void;
  isActive?: boolean;
  initialValue?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  index,
  options,
  onSelect,
  isActive = true,
  initialValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    options[0] || ""
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (initialValue !== undefined) {
      setSelectedOption(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleSelect = (option: string) => {
    if (isActive) {
      // Check if isActive before allowing selection
      setSelectedOption(option);
      onSelect(option, index);
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    if (isActive) {
      // Only toggle if isActive is true
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={`dropdown ${!isActive ? "inactive" : ""} noSelect`}
      ref={dropdownRef}
    >
      <button
        className={`dropdown-button ${isOpen ? "button-open" : ""} ${
          !isActive ? "inactive" : ""
        } paragraphS`} // Apply inactive class if needed
        onClick={toggleDropdown}
        disabled={!isActive} // Disable button if not active
      >
        {selectedOption}
        <span
          className={`dropdown-icon ${isOpen ? "rotate" : ""} ${
            !isActive ? "inactive" : ""
          }`}
        >
          <ArrowDropDownIcon />
        </span>
      </button>
      {isOpen && (
        <ul className="dropdown-menu menu-open">
          {options.map((option, index) => (
            <li
              key={index}
              className="dropdown-item paragraphS"
              onClick={() => handleSelect(option)}
            >
              {option}
              {selectedOption === option && ( // Check if the option is the selected one
                <span className="tick-icon">
                  <CheckIcon />
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
