export enum Invitation {
  Family = "family",
  Pair = "pair",
  Single = "single",
}

export enum Diet {
  Vegan = "vegan",
  Vegetarian = "vegetarian",
  Traditional = "traditional",
  GlutenFree = "glutenfree",
  Milk = "milk",
}
