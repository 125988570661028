import { TableGuest } from "../interfaces/tables/TableGuest";

export const tableGuestData: TableGuest[] = [
  {
    tableNumber: 1,
    guests: [
      "Alice Smith",
      "Bob Johnson",
      "Carol Davis",
      "David Miller",
      "Emily Wilson",
      "Frank Taylor",
      "Grace Evans",
      "Harry Carter",
      "Irene Brown",
    ],
  },
  {
    tableNumber: 2,
    guests: [
      "Jack Anderson",
      "Kate Martinez",
      "Liam Thomas",
      "Mia Robinson",
      "Noah Garcia",
      "Olivia Hernandez",
      "Peter Lee",
    ],
  },
  {
    tableNumber: 3,
    guests: [
      "Quincy Allen",
      "Rachel Baker",
      "Sam Clark",
      "Tina Davis",
      "Ulysses Edwards",
      "Vanessa Fisher",
      "Walter Green",
      "Xena Hill",
    ],
  },
  {
    tableNumber: 4,
    guests: [
      "Yvonne Jackson",
      "Zachary King",
      "Amy Lewis",
      "Ben Moore",
      "Chloe Nelson",
      "Daniel Olson",
      "Emma Parker",
      "Felix Quinn",
      "Gina Reed",
      "Hank Scott",
    ],
  },
  {
    tableNumber: 5,
    guests: [
      "Isaac Turner",
      "Jane White",
      "Kevin Young",
      "Lily Adams",
      "Michael Brown",
      "Natalie Carter",
      "Oliver Davis",
    ],
  },
  {
    tableNumber: 6,
    guests: [
      "Penelope Evans",
      "Quentin Fisher",
      "Rose Garcia",
      "Sebastian Hill",
      "Tiffany Ingram",
      "Upton Jackson",
      "Veronica King",
      "Wesley Lee",
      "Xander Moore",
    ],
  },
  {
    tableNumber: 7,
    guests: [
      "Zoe Nelson",
      "Aaron Olson",
      "Bella Parker",
      "Carlos Quinn",
      "Daisy Reed",
      "Ethan Scott",
      "Fiona Turner",
      "Gary White",
    ],
  },
  {
    tableNumber: 8,
    guests: [
      "Hector Adams",
      "Ingrid Baker",
      "Jacob Carter",
      "Kelly Davis",
      "Leo Evans",
      "Lydia Fisher",
      "Matthew Garcia",
      "Nora Hernandez",
      "Oscar Hill",
      "Paula Ingram",
    ],
  },
  {
    tableNumber: 9,
    guests: [
      "Quincy Johnson",
      "Rachel Martinez",
      "Sam Clark",
      "Tina Davis",
      "Ulysses Edwards",
      "Vanessa Fisher",
      "Walter Green",
    ],
  },
  {
    tableNumber: 10,
    guests: [
      "Xena Hill",
      "Yvonne Jackson",
      "Zachary King",
      "Amy Lewis",
      "Ben Moore",
      "Chloe Nelson",
      "Daniel Olson",
      "Emma Parker",
      "Felix Quinn",
    ],
  },
  {
    tableNumber: 11,
    guests: [
      "Gina Reed",
      "Hank Scott",
      "Isaac Turner",
      "Jane White",
      "Kevin Young",
      "Lily Adams",
      "Michael Brown",
      "Natalie Carter",
    ],
  },
  {
    tableNumber: 12,
    guests: [
      "Oliver Davis",
      "Penelope Evans",
      "Quentin Fisher",
      "Rose Garcia",
      "Sebastian Hill",
      "Tiffany Ingram",
      "Upton Jackson",
      "Veronica King",
      "Wesley Lee",
      "Xander Moore",
    ],
  },
  {
    tableNumber: 13,
    guests: [
      "Zoe Nelson",
      "Aaron Olson",
      "Bella Parker",
      "Carlos Quinn",
      "Daisy Reed",
      "Ethan Scott",
      "Fiona Turner",
    ],
  },
  {
    tableNumber: 14,
    guests: [
      "Gary White",
      "Hector Adams",
      "Ingrid Baker",
      "Jacob Carter",
      "Kelly Davis",
      "Leo Evans",
      "Lydia Fisher",
      "Matthew Garcia",
      "Nora Hernandez",
    ],
  },
  {
    tableNumber: 15,
    guests: [
      "Oscar Hill",
      "Paula Ingram",
      "Quincy Johnson",
      "Rachel Martinez",
      "Sam Clark",
      "Tina Davis",
      "Ulysses Edwards",
      "Vanessa Fisher",
    ],
  },
  {
    tableNumber: 16,
    guests: [
      "Walter Green",
      "Xena Hill",
      "Yvonne Jackson",
      "Zachary King",
      "Amy Lewis",
      "Ben Moore",
      "Chloe Nelson",
      "Daniel Olson",
      "Emma Parker",
      "Felix Quinn",
    ],
  },
  {
    tableNumber: 17,
    guests: [
      "Gina Reed",
      "Hank Scott",
      "Isaac Turner",
      "Jane White",
      "Kevin Young",
      "Lily Adams",
      "Michael Brown",
    ],
  },
  {
    tableNumber: 18,
    guests: [
      "Natalie Carter",
      "Oliver Davis",
      "Penelope Evans",
      "Quentin Fisher",
      "Rose Garcia",
      "Sebastian Hill",
      "Tiffany Ingram",
      "Upton Jackson",
      "Veronica King",
    ],
  },
];
