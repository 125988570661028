import React from "react";
import "../styles/CTAButton.css";

interface SecondaryButtonProps {
  text: string;
  onClick: () => void;
}

const CTAButton: React.FC<SecondaryButtonProps> = ({ text, onClick }) => {
  return (
    <button className="secondary-button noSelect" onClick={onClick}>
      {text}
    </button>
  );
};

export default CTAButton;
