import RecordsComponent from "../components/inv";

export const Invitations = () => {
  return (
    <div>
      <div className="App">
        <div className="App-header">
          <div className="form-container">
            <RecordsComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitations;
