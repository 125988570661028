import React, { useEffect, useState } from "react";
import RadioGroup from "../RadioGroup";
import CTAButton from "../../CTA";
import Dropdown from "../../Dropdown";
import { adultDietOptions } from "../dietOptions";
import { IGuest } from "../../IGuest";
import Typography from "../../Typography";
import RsvpSendConfirmationModal from "../RsvpSendConfirmationModal";
import { GuestRsvpDto } from "../GuestRsvpDto";
import SecondaryButton from "../../SecondaryButton";
import Song from "./Song";

interface RsvpSingleProps {
  guest: IGuest;
  sendForm: (updatedGuests: GuestRsvpDto[], song: string) => void;
  isEditing?: boolean;
  setEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RsvpSingle: React.FC<RsvpSingleProps> = ({
  guest,
  sendForm,
  isEditing,
  setEditing,
}) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [updatedGuest, setUpdatedGuest] = useState<GuestRsvpDto | undefined>();
  const options = Object.values(adultDietOptions);
  const [title, setTitle] = useState("");
  const [confirmed, setConfirmed] = useState<string>();

  useEffect(() => {
    const mountedGuest: GuestRsvpDto = {
      id: guest.id,
      name: guest.name,
      rsvp: true,
      diet: "traditional",
    };

    setUpdatedGuest(mountedGuest);
  }, [guest]);

  const handleSelect = (option: string) => {
    const selectedDiet = Object.keys(adultDietOptions).find(
      (key) => adultDietOptions[key as keyof typeof adultDietOptions] === option
    );

    if (updatedGuest) {
      setUpdatedGuest({
        ...updatedGuest,
        diet: selectedDiet || "",
      });
    }
  };

  const handleConfirmation = (optionValue: string) => {
    setConfirmed(optionValue);
    if (updatedGuest) {
      setUpdatedGuest({
        ...updatedGuest,
        rsvp: optionValue === "true",
      });
    }
  };

  const saveGuest = () => {
    if (updatedGuest) {
      setConfirmationModalOpen(true);
    }
  };

  return (
    <div>
      <div className="section-container">
        <Typography variant="BodyL bold">POTWIERDZENIE OBECNOŚCI</Typography>
        {guest && (
          <div style={{ marginTop: "4px" }}>
            <Typography variant="paragraphS">{guest.name}</Typography>
          </div>
        )}
      </div>
      <div className="section-container">
        <Typography variant="ParagraphM bold">
          Czy będziesz na weselu?
        </Typography>
        <div className="inp-container">
          <RadioGroup
            groupName="confirmation"
            options={[
              { value: "true", label: "Tak, będę" },
              { value: "false", label: "Nie, nie będzie mnie" },
            ]}
            onOptionChange={handleConfirmation}
          />
        </div>
      </div>
      {confirmed !== "false" && (
        <div>
          <h3>Jaką dietę preferujesz?</h3>
          <div className="dropdown-container">
            <div>
              <div className="paragraphS-bold">{guest.name}</div>
              <Dropdown options={options} onSelect={handleSelect} />
            </div>
          </div>
          <Song
            setTitle={setTitle}
            title={title}
            copy="Do jakiej piosenki chętnie zatańczysz?"
          />
        </div>
      )}
      <div style={{ marginTop: "16px" }}>
        {isEditing ? (
          <div className="buttons">
            <CTAButton text="Zapisz zmiany" onClick={saveGuest} />
            <SecondaryButton
              text="Anuluj"
              onClick={() => {
                setEditing && setEditing(false);
              }}
            />
          </div>
        ) : (
          <CTAButton text="Wyślij" onClick={saveGuest} />
        )}
        <RsvpSendConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={() => {
            setConfirmationModalOpen(false);
          }}
          onConfirm={() => {
            if (updatedGuest) {
              setConfirmationModalOpen(false);
              sendForm([updatedGuest], title);
              setEditing && setEditing(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default RsvpSingle;
