import Table from "./Table"; // Adjust the path based on your project structure
import "../../styles/TablesLayout.css";
import { TableLayoutProps } from "../../interfaces/tables/TableLayoutProps";

const TableLayout: React.FC<TableLayoutProps> = ({
  selectedTable,
  setSelectedTable,
}) => {
  const handleTableClick = (tableNumber: number) => {
    setSelectedTable((prevSelectedTable) =>
      prevSelectedTable === tableNumber ? prevSelectedTable : tableNumber
    );
  };

  const createTableClickHandler = (tableNumber: number) => () => {
    handleTableClick(tableNumber);
  };

  const renderTables = (): JSX.Element[] => {
    const tables: JSX.Element[] = [];
    let tableNumber = 1;

    const rowCounts: number[] = [4, 4, 4, 3, 2];

    for (let rowIndex = 0; rowIndex < rowCounts.length; rowIndex++) {
      for (let colIndex = 0; colIndex < rowCounts[rowIndex]; colIndex++) {
        tables.push(
          <Table
            key={tableNumber}
            tableNumber={
              tableNumber < 10 ? `0${tableNumber}` : `${tableNumber}`
            }
            isSelected={selectedTable === tableNumber}
            onClick={createTableClickHandler(tableNumber)}
          />
        );
        tableNumber++;
      }
    }

    return tables;
  };

  return (
    <div className="tables-layout">
      {/* Render each row separately */}
      <div className="tables-row">{renderTables().slice(0, 4)}</div>
      <div className="tables-row">{renderTables().slice(4, 8)}</div>
      <div className="tables-row">{renderTables().slice(8, 12)}</div>
      <div className="tables-row">{renderTables().slice(12, 15)}</div>
      <div className="tables-row">{renderTables().slice(15)}</div>
    </div>
  );
};

export default TableLayout;
