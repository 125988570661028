import React, { useEffect, useState } from "react";
import "../App.css";
import TableLayout from "../components/tables/TableLayout";
import GuestList from "../components/tables/GuestList";
import { tableGuestData } from "../data/tableGuest.data";
import SearchTables from "../components/tables/SearchTables";
import { TableGuest } from "../interfaces/tables/TableGuest";
import api from "../api";

export const Tables = () => {
  const [selectedTable, setSelectedTable] = useState<number | null>(1);
  const [filteredGuests, setFilteredGuests] = useState<string[]>([]);
  const [data, setData] = useState<TableGuest[]>([]);

  useEffect(() => {
    const fetchGuests = async () => {
      const response = await api.guests.getAllTables();
      setData(response.data);
    };
    try {
      fetchGuests();
    } catch (exception) {
      setData(tableGuestData);
    }
  }, []);

  const handleSearch = (searchTerm: string) => {
    // Perform the filtering based on the search term
    const filteredData = data
      .map((tableGuest) => ({
        tableNumber: tableGuest.tableNumber,
        guests: tableGuest.guests.filter((guest) =>
          guest.toLowerCase().startsWith(searchTerm.toLowerCase())
        ),
      }))
      .filter((tableGuest) => tableGuest.guests.length > 0)
      .flatMap((tableGuest) => tableGuest.guests);

    setFilteredGuests(filteredData);
  };
  return (
    <div id="tables">
      <div className="new-page">
        <div className="new-welcome-container">
          <div className="new-illustration tables"></div>
          <div className="title2">STOŁY</div>
          <div className="subtitle subtitle-tables">ZNAJDŹ SWOJE MIEJSCE</div>
        </div>
      </div>
      <div className="page-content2">
        <div style={{ marginTop: "40px", minWidth: "370px" }}>
          {/* <Banner
            title={"Stoły już wkrótce!"}
            subtitle={
              "Informacje na temat usadzenia, pojawią się po potwierdzeniu przez wszystkich obecności 21.08.2024"
            }
          /> */}
        </div>
        <div className="tables-container">
          <p>Lista stołów</p>
          <SearchTables
            onSearch={handleSearch}
            selectedTable={selectedTable}
            tableGuestData={data}
            setSelectedTable={setSelectedTable}
          />
          <div className="tables-and-guests-container">
            <TableLayout
              selectedTable={selectedTable}
              setSelectedTable={setSelectedTable}
            />
            <GuestList
              selectedTable={selectedTable}
              tableGuestData={data}
              filteredGuests={filteredGuests}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tables;
