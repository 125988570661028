import "../styles/Toggle.css";

interface ToggleProps {
  name: string;
  isChecked: boolean;
  onToggle: () => void;
}

const Toggle: React.FC<ToggleProps> = ({ name, isChecked, onToggle }) => {
  return (
    <div className="toggle noSelect">
      <div className="name paragraphS">{name}</div>
      <div className="selector">
        <div
          className={`switch ${isChecked ? "checked" : ""}`}
          onClick={onToggle}
        >
          <div className="base" />
          <div className="knob" />
        </div>
      </div>
      <div className="paragraphS">
        <div className={`switch ${isChecked ? "checked" : ""}`}>
          <div className="label">{`${isChecked ? "Tak" : "Nie"}`}</div>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
