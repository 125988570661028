import React from "react";
import "../App.css";
import Navbar from "../components/navbar";
import { Outlet } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { useLocation, useNavigate } from "react-router-dom";

export const Root = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const right = () => {
    switch (location.pathname) {
      case "/zdjecia":
        navigate("/stoly");
        break;
      case "/stoly":
        navigate("/szczegoly");
        break;
      case "/szczegoly":
        navigate("/nocleg");
        break;
      case "/nocleg":
        navigate("/dojazd");
        break;
      case "/dojazd":
        navigate("/");
        break;
      default:
        break;
    }
  };

  const left = () => {
    switch (location.pathname) {
      case "/":
        navigate("/dojazd");
        break;
      case "/dojazd":
        navigate("/nocleg");
        break;
      case "/nocleg":
        navigate("/szczegoly");
        break;
      case "/szczegoly":
        navigate("/stoly");
        break;
      case "/stoly":
        navigate("/zdjecia");
        break;
      default:
        break;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => left(),
    onSwipedRight: () => right(),
  });

  return (
    <div {...handlers}>
      <Navbar />
      <Outlet />
    </div>
  );
};

export default Root;
