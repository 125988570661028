// App.js
import "./App.css";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./pages/AuthContext";

import Home from "./pages/home";
import Tables from "./pages/tables";
import Details from "./pages/details";
import Accomodation from "./pages/accomodation";
import Photos from "./pages/photos";
import Travel from "./pages/travel";
import Countdown from "./pages/countdown";
import Invitations from "./pages/invitations";
import Guests from "./pages/guests";
import LoginPage from "./pages/login";
import Rsvp from "./pages/rsvp";
import ErrorPage from "./pages/error";
import Root from "./pages/root";
import PrivateRoute from "./pages/PrivateRoute";
import LoginComponent from "./pages/LoginComponent";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Home /> },
          { path: "/dojazd", element: <Travel /> },
          { path: "/stoly", element: <Tables /> },
          { path: "/szczegoly", element: <Details /> },
          { path: "/nocleg", element: <Accomodation /> },
          { path: "/zdjecia", element: <Photos /> },
          { path: "/rsvp", element: <Rsvp /> },
          { path: "/login", element: <LoginComponent /> },
          {
            path: "/invitations",
            element: <PrivateRoute component={Invitations} />,
          },
          { path: "/countdown", element: <Countdown /> },
          { path: "/guests", element: <PrivateRoute component={Guests} /> },
          { path: "/", element: <Home /> },
          { path: "/rsvp/:uuid", element: <LoginPage /> },
        ],
      },
    ],
  },
]);

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
};

export default App;
