import React from "react";
import "../App.css";

export const Photos = () => {
  return (
    <div id="photos">
      <div className="new-page">
        <div className="new-welcome-container">
          <div className="new-illustration photos"></div>
          <div className="title2">
            ZDJĘCIA
            <div>
              <div className="subtitle subtitle-photos">
                GALERIA JUŻ NIEBAWEM
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content-photos">
        <div style={{ textAlign: "center", width: "80%" }}>
          W tym miejscu pojawią się zdjęcia oraz film z naszego wyjątkowego
          dnia!
        </div>
      </div>
    </div>
  );
};

export default Photos;
