// GuestList.tsx

import { GuestListProps } from "../../interfaces/GuestListProps";
import styles from "../../styles/tables.module.css";

const GuestList: React.FC<GuestListProps> = ({
  tableGuestData,
  selectedTable,
  filteredGuests,
}) => {
  const formatTableNumber = (selectedTable: number | null): string => {
    return selectedTable !== null
      ? selectedTable < 10
        ? `0${selectedTable}`
        : `${selectedTable}`
      : "N/A";
  };

  // Filter guests based on the selected table number
  const selectedTableData = tableGuestData.find(
    (table) => table.tableNumber === selectedTable
  );

  // const guestsToDisplay = filteredGuests || selectedTableData?.guests;

  return (
    <div className={styles.container}>
      <div className={styles.tableName}>
        Stół {formatTableNumber(selectedTable)}
      </div>
      <hr className={styles.separator} />
      {/* <p>Filtered data: {filteredGuests?.length}</p>
      <ul>
        {guestsToDisplay?.map((guest, index) => (
          <li key={index}>{guest}</li>
        ))}
      </ul> */}
      <ul>
        {selectedTableData?.guests.map((guest, index) => (
          <li key={index}>{guest}</li>
        ))}
      </ul>
    </div>
  );
};

export default GuestList;
