import React from "react";
import Countdown from "react-countdown";

const DateCountdown = () => {
  return (
    <div>
      <Countdown
        date="2024-09-21T17:00:00Z"
        autoStart={true}
        renderer={(props) => (
          <div>
            <h2>{`${props.days}d ${props.hours}h ${props.minutes}m ${props.seconds}s`}</h2>
          </div>
        )}
      />
    </div>
  );
};

export default DateCountdown;
