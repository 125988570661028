import React from "react";
import "../../styles/Table.css";
import { TableProps } from "../../interfaces/tables/TableProps";

const Table: React.FC<TableProps> = ({ tableNumber, isSelected, onClick }) => {
  const containerWidth = 80; // Adjust container width as needed
  const containerHeight = 80; // Adjust container height as needed
  const tableRadius = 30; // Radius of the big table
  const chairRadius = 7; // Radius of the small chairs
  const chairOffset = -2; // Constant value for chair offset
  const centerX = containerWidth / 2;
  const centerY = containerHeight / 2;

  const getChairPosition = (angle: number) => {
    const radians = (angle * Math.PI) / 180;
    const x = centerX + (tableRadius + chairOffset) * Math.cos(radians);
    const y = centerY + (tableRadius + chairOffset) * Math.sin(radians);
    return { x, y };
  };

  return (
    <div
      className={`table-container ${isSelected ? "selected" : ""}`}
      style={{ width: containerWidth, height: containerHeight }}
      onClick={onClick}
    >
      <div className="table">
        <span className="number">{tableNumber}</span>
      </div>
      {Array.from({ length: 6 }).map((_, index) => {
        const angle = 60 * index;
        const { x, y } = getChairPosition(angle);
        return (
          <div
            key={index}
            className="chair"
            style={{
              top: `${y - chairRadius}px`,
              left: `${x - chairRadius}px`,
            }}
          ></div>
        );
      })}
    </div>
  );
};

export default Table;
