import React from "react";
import styles from "../styles/Agenda.module.css";

interface AccordionProps {
  title: string;
  hour: string;
}

const AgendaEntry: React.FC<AccordionProps> = ({ title, hour }) => {
  return (
    <div className={styles.entry}>
      <div className="paragraphS-bold">{hour}</div>
      <div className={styles.entryLine}></div>
      <div className={styles.entryTitle}>{title}</div>
    </div>
  );
};

export default AgendaEntry;
