import React, { useState, useEffect } from "react";
import { copyImageToClipboard } from "copy-image-clipboard";
import "../styles/Invitation.css";
import { PaginationProps } from "./PaginationProps";
import { IInvitation } from "./IInvitation";
import api from "../api";

const InvitationPage: React.FC = () => {
  const [records, setRecords] = useState<IInvitation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const recordsPerPage: number = 10;
  const [copiedStates, setCopiedStates] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await api.invitations.getAllInvitations();
        setRecords(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100); // Adjust the delay time (in milliseconds) as needed

    return () => clearTimeout(timeout);
  }, [currentPage]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const indexOfLastRecord: number = currentPage * recordsPerPage;
  const indexOfFirstRecord: number = indexOfLastRecord - recordsPerPage;
  const currentRecords: IInvitation[] = records.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const paginate = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const copyQRCode = async (id: string) => {
    try {
      await copyImageToClipboard(qrUrl(id));
      setCopiedStates((prev) => ({ ...prev, [id]: true })); // Set Copied state for this button
      // setTimeout(() => {
      //   setCopiedStates((prev) => ({ ...prev, [id]: false })); // Reset Copied state after 3 seconds
      // }, 3000);
    } catch (error) {}
  };

  const copyContentOrCode = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content.toUpperCase());
      setCopiedStates((prev) => ({ ...prev, [content]: true })); // Set Copied state for this button
      // setTimeout(() => {
      //   setCopiedStates((prev) => ({ ...prev, [content]: false })); // Reset Copied state after 3 seconds
      // }, 3000);
    } catch (error) {
      console.error("Failed to copy content: ", error);
    }
  };

  const qrUrl = (id: string): string => {
    return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&color=292929&data=wesele.przerywacz.pl/rsvp/${id}`;
  };

  return (
    <div>
      {currentRecords.map((record, index) => (
        <div key={record.id} className="record-item-stack">
          <div className="index">#{indexOfFirstRecord + index + 1}</div>
          <p>{record.content.toUpperCase()}</p>
          <button
            className={`copyButton ${
              copiedStates[record.content] ? "copied" : ""
            }`}
            onClick={() => copyContentOrCode(record.content)}
          >
            {copiedStates[record.content] ? "Copied" : "Copy Content"}
          </button>
          <p>{record.code}</p>
          <button
            className={`copyButton ${
              copiedStates[record.code] ? "copied" : ""
            }`}
            onClick={() => copyContentOrCode(record.code)}
          >
            {copiedStates[record.code] ? "Copied" : "Copy Code"}
          </button>
          <div className="qr-code">
            <img
              src={qrUrl(record.id)}
              alt={`QR Code for record with id ${record.id}`}
            />
          </div>
          <button
            className={`copyButton ${copiedStates[record.id] ? "copied" : ""}`}
            onClick={() => copyQRCode(record.id)}
          >
            {copiedStates[record.id] ? "Copied" : "Copy QR Code"}
          </button>
          <ol>
            {record.guests.map((guest, index) => (
              <li key={index}>{guest.name}</li>
            ))}
          </ol>
        </div>
      ))}
      <Pagination
        recordsPerPage={recordsPerPage}
        totalRecords={records.length}
        currentPage={currentPage} // Pass currentPage to Pagination component
        paginate={paginate}
      />
    </div>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  recordsPerPage,
  totalRecords,
  currentPage,
  paginate,
}) => {
  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default InvitationPage;
