import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  useEffect(() => {
    if (uuid) {
      // Store UUID in localStorage or session storage
      localStorage.setItem("uuid", uuid);
      //   setTimeout(function () {
      //     localStorage.removeItem("uuid");
      //   }, 5000);

      // Navigate to dashboard or any desired route
      navigate("/");
    }
  }, [navigate, uuid]);

  return (
    <div>
      <p>Login Page</p>
      {/* Render your login form here if needed */}
    </div>
  );
};

export default LoginPage;
