import React from "react";
import "../styles/Typography.css";

interface TypographyProps {
  variant: string;
  color?: string;
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  variant,
  color,
  children,
}) => {
  const className = variant
    .split(" ")
    .map((v) => `typography-${v}`)
    .join(" ");

  const style = color ? { color } : {};

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Typography;
