import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IGuest } from "./IGuest";

export interface DietStatsCardProps {
  guests: IGuest[] | undefined;
}

const DietStatsCard: React.FC<DietStatsCardProps> = ({ guests }) => {
  const [traditional, setTraditional] = useState<number>();
  const [vegan, setVegan] = useState<number>();
  const [vegetarian, setVegetarian] = useState<number>();
  const [glutenFree, setGlutenFree] = useState<number>();
  const [milk, setMilk] = useState<number>();

  useEffect(() => {
    setTraditional(
      guests?.filter(
        (guest) => guest.diet === "traditional" && guest.rsvp === true
      ).length
    );
    setVegan(
      guests?.filter((guest) => guest.diet === "vegan" && guest.rsvp === true)
        .length
    );
    setVegetarian(
      guests?.filter(
        (guest) => guest.diet === "vegetarian" && guest.rsvp === true
      ).length
    );
    setGlutenFree(
      guests?.filter(
        (guest) => guest.diet === "glutenfree" && guest.rsvp === true
      ).length
    );
    setMilk(
      guests?.filter((guest) => guest.diet === "milk" && guest.rsvp === true)
        .length
    );
  }, [guests]);

  return (
    <Card
      sx={{
        padding: "20px",
        borderRadius: "16px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Statystyki według diety:</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Tradycyjna</TableCell>
              <TableCell>{traditional}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wegańska</TableCell>
              <TableCell>{vegan}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wegetariańska</TableCell>
              <TableCell>{vegetarian}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bezglutenowa</TableCell>
              <TableCell>{glutenFree}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mleczko</TableCell>
              <TableCell>{milk}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DietStatsCard;
