import React from "react";
import "../App.css";
import ArrowToLeft from "../components/ArrowToLeft";
import Detail from "../components/Detail";
import ArrowToRight from "../components/ArrowToRight";

export const Travel = () => {
  return (
    <div id="travel">
      <div className="new-page">
        <div className="new-welcome-container">
          <div className="new-illustration travel"></div>
          <div className="title2">DOJAZD</div>
          <div className="subtitle subtitle-travel">CZYLI DANE DO UBERA</div>
        </div>
      </div>
      <div className="page-content">
        <div className="arrow-container">
          <ArrowToLeft />
          <Detail
            title="ŚLUB"
            firstline="Katedra Łódzka"
            secondline="ul. Księdza Ignacego Skorupki 9"
            thirdline="90-457 Łódź"
            map="https://maps.app.goo.gl/iCTQzqk5VBEGLm146"
            textAlign={"left"}
          />
          <ArrowToRight />
          <Detail
            title="WESELE"
            firstline="Hotel Czarny Staw - Sala Platynowa"
            secondline="Nowy Adamów 3A"
            thirdline="95-070 Aleksandrów Łódzki"
            map="https://maps.app.goo.gl/6h3hjUnFiy8sXx838"
            textAlign={"right"}
          />
          <ArrowToLeft />
          <Detail
            title="NOCLEG"
            firstline="Chcesz dowiedzieć się gdzie śpisz?"
            link="/nocleg"
            textAlign={"left"}
          />
        </div>
      </div>
    </div>
  );
};

export default Travel;
