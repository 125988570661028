import Typography from "../../components/Typography";

const OwnHomeDetails: React.FC = () => {
  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <Typography variant="Display2">NIE MA TO JAK W DOMU</Typography>
      </div>
      <Typography variant="ParagraphM">
        Nie uwzględniliśmy dla Ciebie noclegu. Jeśli jednak potrzebujesz noclegu
        tego dnia, zadzwoń do nas.
      </Typography>
      <div style={{ fontSize: "14px" }}>
        <a className="third-button" href="tel: +48 500 071 538">
          +48 500 071 538
        </a>{" "}
        Patrycja <br />
        <a className="third-button" href="tel: +48 511 819 501">
          +48 511 819 501
        </a>{" "}
        Tomek
      </div>
    </>
  );
};

export default OwnHomeDetails;
