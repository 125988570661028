import React, { useState } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import LoginModal from "../components/home/LoginModal/LoginModal";

export const Home = () => {
  const uuid = localStorage.getItem("uuid");
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const confirmClick = () => {
    if (uuid) {
      navigate("/rsvp");
    } else {
      openModal();
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div id="home" className="noSelect">
      <div className="new-page">
        <div className="new-welcome-container">
          <div className="new-illustration home"></div>
          <div className="title2">WESELE</div>
          <div className="subtitle subtitle-home">PATRYCJI I TOMKA</div>
        </div>
      </div>
      <div className="new-page-content">
        <button className="new-confirm-button" onClick={confirmClick}>
          Potwierdź obecność
        </button>
      </div>
      <LoginModal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={"LOGOWANIE"}
        subtitle={"Zeskanuj kod QR, lub wpisz kod z zaproszenia!"}
      ></LoginModal>
    </div>
  );
};

export default Home;
